import React, { useState } from "react";
import { Table as SemanticTable } from "semantic-ui-react";
import { Table, ClearableCheckboxDropdown } from "dyl-components";
import { Icon } from "semantic-ui-react";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import { useSearchParams } from "react-router-dom";
import VoicemailBoxPinInfoModal from "shared/modals/voicemailBoxPinInfoModal";

const ExtensionsCompanyTableHeader = ({ extensions, onFilter }) => {
    const [params] = useSearchParams();

    const [isVoicemailInfoModalOpen, setIsVoicemailInfoModalOpen] =
        useState(false);

    const SortActions = ({ direction = false, onToggleSort = () => {} }) => {
        return (
            <div
                onClick={onToggleSort}
                style={{ display: "inline-flex", cursor: "pointer" }}
            >
                <Icon
                    name="fa-solid fa-arrow-down-long"
                    style={{
                        marginRight: -7,
                        opacity: !direction ? "100%" : "50%",
                    }}
                />
                <Icon
                    name="fa-solid fa-arrow-up-long"
                    style={{ opacity: direction ? "100%" : "50%" }}
                />
            </div>
        );
    };

    const extensionOrderFilter = () => {
        const order = params.get("order") === "desc" ? "asc" : "desc";
        onFilter(null, { name: "order", value: order });
    };

    return (
        <Table.Row>
            <SemanticTable.HeaderCell width={1}>
                <SelectAllCheckbox
                    count={extensions.count}
                    data={extensions.data || []}
                    type={"extensions"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                Extension
                <SortActions
                    direction={params.get("order") === "desc"}
                    onToggleSort={() => {
                        extensionOrderFilter();
                    }}
                />
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                <ClearableCheckboxDropdown
                    text="Type"
                    name="type"
                    options={[
                        {
                            key: "companyVMBox",
                            value: "voicemail_box",
                            text: "Company VM Box",
                        },
                        {
                            key: "conferenceRoom",
                            value: "conference_room",
                            text: "Conference Room",
                        },
                        {
                            key: "parkingLot",
                            value: "parking_lot",
                            text: "Parking Lot",
                        },
                    ]}
                    onChange={(_, { value }) => {
                        onFilter(_, { name: "type", value });
                    }}
                    value={params.get("type")?.split(",")}
                    closeOnLoad={true}
                />
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>Name</SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                Office View Display
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                <p className="voicemailBoxHeader">
                    {" "}
                    Voicemail Box PIN
                    <Icon
                        className="fas fa-circle-info voiceboxPin-info-icon"
                        style={{ marginLeft: 2, marginTop: 2 }}
                        onClick={() => setIsVoicemailInfoModalOpen(true)}
                    />
                </p>
                <VoicemailBoxPinInfoModal
                    setIsVoicemailInfoModalOpen={setIsVoicemailInfoModalOpen}
                    isVoicemailInfoModalOpen={isVoicemailInfoModalOpen}
                />
            </SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>Audio</SemanticTable.HeaderCell>

            <SemanticTable.HeaderCell width={1}>
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default ExtensionsCompanyTableHeader;
