import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';
import { STATUS_TYPES, Notification } from 'dyl-components';

import axiosInstance from 'actions/axiosInstance';
import CRUD_ACTION_TYPES, { getNamedAction } from '../CRUD_ACTION_TYPES';
let client = axiosInstance.instance1;

const getDomain = generateReadActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_DOMAIN);

const getDevices = generateReadOneActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_DEVICES, 'devices');
const createDevices = generateCreateActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_DEVICES, () => { }, () => { }, 'devices');
const updateDevice = generateUpdateActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_DEVICES, () => { }, () => { }, 'devices');
const deleteDevice = generateDeleteActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_DEVICES, () => { }, () => { }, 'devices');

const getLocations = generateReadOneActionCreator(routes.API_PROVISION_LOCATIONS, ACTION_NAMES.PROVISION_LOCATIONS);
const createLocations = generateCreateActionCreator(routes.API_PROVISION_LOCATIONS, ACTION_NAMES.PROVISION_LOCATIONS, () => {}, () => {}, 'locations');

const validateLocation = generateCreateActionCreator(routes.API_PROVISION_VALIDATE_LOCATION, ACTION_NAMES.PROVISION_VALIDATE_LOCATION);

const updateLocation = generateUpdateActionCreator(routes.API_PROVISION_LOCATIONS, ACTION_NAMES.PROVISION_LOCATION, () => {
    Notification.alert('Successfully updated location!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to update location', STATUS_TYPES.ERROR, true);
}, 'locations');
const deleteLocation = generateDeleteActionCreator(routes.API_PROVISION_LOCATIONS, ACTION_NAMES.PROVISION_LOCATION);

const getSoftphones = generateReadOneActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_SOFTPHONES, 'softphones');
const getSoftphone = generateReadOneActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_SOFTPHONE, 'softphones');

const createSoftphones = generateCreateActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_SOFTPHONES, () => {
    Notification.alert('Successfully created softphone!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to create a softphone', STATUS_TYPES.ERROR, true);
}, 'softphones');

const updateSoftphone = generateUpdateActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_SOFTPHONE, () => {
    Notification.alert('Successfully updated softphone!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to update softphone', STATUS_TYPES.ERROR, true);
}, 'softphones');
const deleteSoftphone = generateDeleteActionCreator(routes.API_PROVISION_DOMAIN, ACTION_NAMES.PROVISION_SOFTPHONE, () => {
    Notification.alert('Successfully deleted softphone!', STATUS_TYPES.SUCCESS, true);
}, () => {
    Notification.alert('Failed to delete softphone', STATUS_TYPES.ERROR, true);
}, 'softphones');

const reactivateSoftphone = (domain, softphone) => {
    return dispatch => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_REQUEST),
            id: domain,
            subId: softphone
        });
        return client.put(`${routes.API_PROVISION_DOMAIN}/${domain}/softphones/${softphone}/reactivate`)
            .then((response) => {
                console.log(response);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_SUCCESS),
                    id: domain,
                });

                Notification.alert('Successfully reactivated softphone!', STATUS_TYPES.SUCCESS, true);
            })
            .catch((error) => {
                console.log(error);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE_REACTIVATE, CRUD_ACTION_TYPES.UPDATE_FAILURE)
                });

                Notification.alert('Failed to reactivate softphone', STATUS_TYPES.ERROR, true);

                return Promise.reject(error);
            })
    }
}

const deactivateSoftphone = (domain, softphone) => {
    return dispatch => {
        dispatch({
            type: getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE_DEACTIVATE, CRUD_ACTION_TYPES.UPDATE_REQUEST),
            id: domain,
            subId: softphone
        });
        return client.put(`${routes.API_PROVISION_DOMAIN}/${domain}/softphones/${softphone}/deactivate`)
            .then((response) => {
                console.log(response);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE_DEACTIVATE, CRUD_ACTION_TYPES.UPDATE_SUCCESS),
                    id: domain,
                });

                Notification.alert('Successfully deactivated softphone!', STATUS_TYPES.SUCCESS, true);
            })
            .catch((error) => {
                console.log(error);

                dispatch({
                    type: getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE_DEACTIVATE, CRUD_ACTION_TYPES.UPDATE_FAILURE)
                });

                Notification.alert('Failed to deactivate softphone', STATUS_TYPES.ERROR, true);

                return Promise.reject(error);
            })
    }
}

export default {
    getDevices,
    createDevices,
    updateDevice,
    deleteDevice,

    getLocations,
    createLocations,
    validateLocation,
    updateLocation,
    deleteLocation,

    getSoftphone,
    getSoftphones,
    createSoftphones,
    updateSoftphone,
    deleteSoftphone,
    reactivateSoftphone,
    deactivateSoftphone,

    getDomain
}
