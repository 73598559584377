import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function provisionReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PROVISION_DOMAIN, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingDomain: true };
        case getNamedAction(ACTION_NAMES.PROVISION_DOMAIN, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingDomain: false, domain: action.data.id };
        case getNamedAction(ACTION_NAMES.PROVISION_DOMAIN, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDomain: false, domain: 0 };

        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingDevices: true };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, devices: action.data.data, device_count: action.data.count, isReadingDevices: false };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingDevices: false };

        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingDevices: true };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingDevices: false };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingDevices: false };

        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, deviceBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, deviceBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, deviceBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, deviceBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, deviceBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PROVISION_DEVICES, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, deviceBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingLocations: true };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingLocations: false, locations: action.data.data, locationsCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingLocations: false };

        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingLocations: true };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingLocations: false };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATIONS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingLocations: false };

        case getNamedAction(ACTION_NAMES.PROVISION_VALIDATE_LOCATION, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isValidatingLocation: true };
        case getNamedAction(ACTION_NAMES.PROVISION_VALIDATE_LOCATION, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isValidatingLocation: false, isValidLocation: action.data.valid, locationSuggestion: action.data.location };
        case getNamedAction(ACTION_NAMES.PROVISION_VALIDATE_LOCATION, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isValidatingLocation: false };

        case getNamedAction(ACTION_NAMES.PROVISION_LOCATION, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, locationBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATION, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, locationBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATION, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, locationBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PROVISION_LOCATION, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, locationBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATION, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, locationBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PROVISION_LOCATION, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, locationBeingDeleted: null };

        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingSoftphones: true };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingSoftphones: false, softphones: action.data.data };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingSoftphones: false };

        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, softphoneBeingRead: action.subId };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            const { password, server } = action.data;
            const softphones = state.softphones.slice(0);
            const indexOfSoftphoneToModify = softphones.findIndex(({ id }) => state.softphoneBeingRead === id);
            softphones[indexOfSoftphoneToModify].credentials = {
                password, server
            }
            return { ...state, softphones, softphoneBeingRead: null };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, softphoneBeingRead: null };

        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, softphoneBeingUpdated: action.subId };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, softphoneBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, softphoneBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, softphoneBeingDeleted: action.subId };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, softphoneBeingDeleted: null };
        case getNamedAction(ACTION_NAMES.PROVISION_SOFTPHONE, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, softphoneBeingDeleted: null };

        default:
            return state;
    }
}


export default provisionReducer;
