const initialState = {
    recordings: null,
    isReadingRecordings: false,
    isUpdatingRecordings: false,

    preferences: null,
    isReadingPreferences: false,
    isUpdatingPreferences: false,

    dylNumbers: [],
    dylNumbersCount: 0,
    isReadingDylNumbers: false,

    dylNumber: null,
    isReadingDylNumber: false,

    destinations: null,
    isReadingDestinations: false,

    destinationGroups: [],
    destinationGroup: null,
    destinationGroupsCount: 0,
    isReadingDestinationGroups: false,
    isSavingDestinationGroups: false,
    isDeletingDestinationGroup: false,
    isReadingDestinationGroup: false,
    isEditingDestinationGroup: false,
    
    isUpdatingDylNumber: false,
    
    companyExtensions: {},
    isReadingCompanyExtensions: false,
    isReadingListPhones: false,
    pbxListPhones: [],

    callRoutingRules: [],
    callRoutingRulesCount: 0,
    isReadingCallRoutingRules: false
}

export default initialState;
