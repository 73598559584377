import React, { useEffect } from 'react';
import { Header, Form, Button } from 'semantic-ui-react';
import { Modal, generateResolver, yup, Notification, STATUS_TYPES } from 'dyl-components';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import UserTableDropdown from './UserTableDropdown';
import campaignActions from 'actions/campaigns';
import campaignUsersActions from 'actions/campaign_users';

const MAX_USERS = 200;

const AddUserReportModal = ({ campaignId, isModalOpen, onModalClose }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(campaignActions.readCampaignUserIds(campaignId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId]);

    const { loggedUserId, campaignUserIds, campaignUserIdsCount, isReadingCampaignUserIds, isAddingUserReport } = useSelector(state => ({
        loggedUserId: state.auth.user_id,
        campaignUserIds: state.campaigns.campaignUserIds,
        campaignUserIdsCount: state.campaigns.campaignUserIdsCount,
        isReadingCampaignUserIds: state.campaigns.isReadingCampaignUserIds,
        isAddingUserReport: state.campaigns.isAddingUserReport,
    }));

    const { control, formState: { isValid }, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            users: []
        },
        resolver: generateResolver({
            users: yup.array().of(yup.object().shape({
                user_id: yup.number()
            })).min(1, 'This field is required').max(MAX_USERS - campaignUserIdsCount, 'A campaign can only have 200 users')
        })
    });

    const onSave = async (data) => {
        try {
            const payload = data.users.map(user => user.user_id);
            await dispatch(campaignActions.createCampaignUserReport(payload, null, campaignId));
            Notification.alert('Successfully added user to campaign!', STATUS_TYPES.SUCCESS);
            onModalClose();
            dispatch(campaignActions.readCampaignUserIds(campaignId));
            dispatch(campaignUsersActions.readCampaignUsers(campaignId));
            reset();
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to add user to campaign', STATUS_TYPES.ERROR);
        }
    }

    return (
        <Modal open={isModalOpen} onClose={onModalClose}>
            <Modal.Header>
                Add User to Campaign
            </Modal.Header>
            <Modal.Content style={{ minHeight: '25rem' }}>
                <Header as='h4'>
                    Campaign Access
                    <Header.Subheader>
                        Grant team members access campaign and any contacts in the campaign.
                    </Header.Subheader>
                </Header>
                <Form loading={isAddingUserReport || isReadingCampaignUserIds}>
                    <Controller
                        name="users"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Field
                                control={UserTableDropdown}
                                label="Teams"
                                value={value}
                                excludedUserIds={[{ user_id: loggedUserId }, ...campaignUserIds.map(user => ({ user_id: user.user_id }))]}
                                onChange={(_, newValue) => { onChange({ target: { name, value: newValue } }) }}
                                errorMessage={error && error.message}
                            />
                        )}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button primary disabled={!isValid || isAddingUserReport} onClick={handleSubmit(onSave)}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default AddUserReportModal;
