import { AttachableItem } from 'dyl-components';
import React from 'react';
import { Divider, Input, Segment, Tab, Menu } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';

const recordFields = [
    {
        value: 'new', text: 'New', field: 'triggers', disabled: true
    },
    {
        value: 'existing', text: 'Existing', field: 'triggers',  disabled: true
    },
];

const conversionFields = [
    {
        value: 'converts', text: 'Converts', field: 'triggers',
    },
];

const routingFields = [
    {
        value: 'roundrobin', text: 'Round Robin', field: 'routing', disabled: false
    },
    {
        value: 'lead_connect', text: 'Lead Connect', field: 'routing', disabled: true
    },
    {
        value: 'shark_tank', text: 'Shark Tank', field: 'routing', disabled: true
    },
];

export const FieldToggles = ({
    fieldOptions,
    control,
    segmentControl,
    isActiveTriggers,
    resetTriggers,
    hasSegments,
    onChangeActiveInnerTab,
    activeInnerTab,
    onAddCondition,
    onRemoveCondition,
    updateRoutings,
    activeSegment,
    hasRoutings,
    resetRouting,
    watch,
    watchedSegments,
    search,
    setSearch
}) => {
    const watchedRoutings = watch("routings")?.[0]?.routingActiveFields || []

    const filterFieldsByLabel = () => {
        return field => field.text.toLowerCase().includes(search.toLowerCase());
    }

    const criteriaOptions = [
        {className: "FieldGroupClassification", disabled: true, key: "Criteria Option", primary_header: true, text: "Criteria"},
        {className: "FieldCategory", disabled: true, key: "Criteria", header: true, text: "Criteria"},
        {className: "FieldCategory__option", disabled: true, key: "criteria", text: "Criteria", options: false, parent: "Criteria", value: "criteria", description: "Master/Secondary Sources, Data Provider/Record, Campaign Converted, Product Interests"}
    ];

    const filterRecordFields = recordFields.slice(0).filter(filterFieldsByLabel());
    const filterConversionFields = conversionFields.slice(0).filter(filterFieldsByLabel());
    const filterRoutingFields = routingFields.slice(0).filter(filterFieldsByLabel());
    const fieldOptionsFields = [...criteriaOptions, ...fieldOptions]?.slice(0).filter(filterFieldsByLabel());

    const isFieldActive = (fieldKey) => {
        return !!watchedSegments?.[activeSegment]?.conditions?.find(({field}) => field === fieldKey);
    }

    const handleTabChange = (_, { activeIndex }) => {
        onChangeActiveInnerTab(activeIndex)
    }

    return (
        <Tab
            onTabChange={handleTabChange}
            activeIndex={activeInnerTab}
            menu={{ color: 'primary', fluid: true, className: 'centered Assignment__Rules-categories' }}
            panes={[
                {
                    menuItem: <Menu.Item>Triggers</Menu.Item>,
                    render: () => (
                        <Controller
                            name='triggerActiveFields'
                            control={control}
                            rules={{
                                validate: {
                                    not_empty: (value) => {
                                        return (value && value.length > 0) || "This field is required"
                                    }
                                }
                            }}
                            render={({ field: { onChange, value: triggerActiveFields, name } }) => {
                                const onToggleField = (field) => {
                                    const isActive = !!triggerActiveFields?.find((activeField) => activeField.value === field.value)
                                    if (!isActive) {
                                        onChange({
                                            target: {
                                                name,
                                                value: [...triggerActiveFields, {
                                                    ...field,
                                                }]
                                            }
                                        });
                                    } else {
                                        resetTriggers();
                                    }
                                };
                                return (
                                    <React.Fragment>
                                        <Divider hidden />
                                        <div className='Assignment__Rules-options'>
                                            {filterRecordFields.length > 0 && (
                                                <Segment size='tiny' textAlign='left' className='Assignment__Rules-category'>
                                                    <div>
                                                        <strong>Record/Module</strong>
                                                    </div>
                                                    {filterRecordFields.map(field => (
                                                        <AttachableItem
                                                            key={field.value}
                                                            active={!!triggerActiveFields.find(activeField => activeField.value === field.value)}
                                                            label={field.text}
                                                            name={field.value}
                                                            size={'tiny'}
                                                            disabled={field.disabled || (isActiveTriggers && !!!triggerActiveFields.find(activeField => activeField.value === field.value))}
                                                            onToggle={() => { onToggleField(field); }}
                                                        />
                                                    ))}
                                                </Segment>
                                            )}
                                            {filterConversionFields.length > 0 && (
                                                <Segment size='tiny' textAlign='left' className='Assignment__Rules-category'>
                                                    <div>
                                                        <strong>Conversion</strong>
                                                    </div>
                                                    {filterConversionFields.map(field => (
                                                        <AttachableItem
                                                            key={field.value}
                                                            active={!!triggerActiveFields.find(activeField => activeField.value === field.value)}
                                                            label={field.text}
                                                            name={field.value}
                                                            size={'tiny'}
                                                            disabled={(isActiveTriggers && !!!triggerActiveFields.find(activeField => activeField.value === field.value))}
                                                            onToggle={() => { onToggleField(field); }}
                                                        />
                                                    ))}
                                                </Segment>
                                            )}
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                        />
                    )
                },
                {
                    menuItem: <Menu.Item disabled={!isActiveTriggers || !hasSegments}>Segments</Menu.Item>,
                    render: () => (
                        <React.Fragment>
                            <Divider hidden />
                            <Input
                                fluid
                                size='small'
                                icon='search'
                                iconPosition='left'
                                type='search'
                                placeholder='Search for a field name'
                                className='Assignment__Rules-search'
                                onChange={(_, { value }) => { setSearch(value) }}
                            />
                            <Tab
                                menu={{ color: 'primary', fluid: true, className: 'centered Assignment__Rules-categories' }}
                                panes={[
                                    {
                                        menuItem: 'Standard',
                                        render: () => (
                                            <Controller
                                                name={`activeFields`}
                                                control={segmentControl}
                                                render={({ field: { onChange, value: activefields, name } }) => {
                                                    return (
                                                        <React.Fragment>
                                                            <Divider hidden />
                                                            <div className='Assignment__Rules-options'>
                                                                {fieldOptionsFields.length > 0 && (
                                                                    <Segment size='tiny' textAlign='left' className='Assignment__Rules-category'>
                                                                        {fieldOptionsFields.map(field => {
                                                                            if (field.primary_header || field.header) {
                                                                                if (field.primary_header) {
                                                                                    return '';
                                                                                } else {
                                                                                    return field.text;
                                                                                }
                                                                            }
                                                                            const isActive = isFieldActive(field.key);
                                                                            return (
                                                                                <AttachableItem
                                                                                    key={field.value}
                                                                                    active={isActive}
                                                                                    label={field.text}
                                                                                    name={field.value}
                                                                                    size={'tiny'}
                                                                                    description={field.description}
                                                                                    onToggle={() => {
                                                                                        if (isActive) {
                                                                                            onRemoveCondition(field);
                                                                                        } else {
                                                                                            onAddCondition(field);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            );
                                                                        }
                                                                        )}
                                                                    </Segment>
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }}
                                            /> 
                                        )
                                    },
                                    {
                                        menuItem: 'Custom',
                                    },
                                ]}
                            />
                        </React.Fragment>
                    )
                },
                {
                    menuItem: <Menu.Item disabled={!isActiveTriggers || !hasRoutings}>Routing</Menu.Item>,
                    render: () => (
                        <Controller
                            name='routings[0].routingActiveFields'
                            control={segmentControl}
                            rules={{
                                validate: {
                                    not_empty: (value) => {
                                        return (value && value.length > 0) || "This field is requred"
                                    }
                                }
                            }}
                            render={({ field: { onChange, value: routingActiveFields, name } }) => {
                                return (
                                    <React.Fragment>
                                        <Divider hidden />
                                        <div className='Assignment__Rules-options'>
                                            {filterRoutingFields.length > 0 && filterRoutingFields.map(field => {
                                                const isActive = watchedRoutings.find((activeField) => activeField.value === field.value)
                                                return (
                                                    <div className='Assignment__Rules-option'>
                                                        <AttachableItem
                                                            key={field.value}
                                                            active={isActive}
                                                            label={field.text}
                                                            name={field.value}
                                                            size={'tiny'}
                                                            disabled={field.disabled}
                                                            onToggle={() => {
                                                                if (!isActive) {
                                                                    updateRoutings(field);
                                                                    onChange({target: {
                                                                        name,
                                                                        value: [...routingActiveFields, field]
                                                                    }});
                                                                } else {
                                                                    resetRouting();
                                                                    onChange({target: {
                                                                        name,
                                                                        value: [...routingActiveFields.filter((activeField) => activeField.value !== field.value)]
                                                                    }});
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                        />
                    )
                }
            ]} 
        />);
}
