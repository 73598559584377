import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Grid, Icon, Popup, Label } from 'semantic-ui-react';
import EmailsField from 'shared/ContactInformation/EmailsField';
import PhonesField from 'shared/ContactInformation/PhonesField';
import { CustomGroupUtils, StringUtils } from 'utils';
import LocationsView from 'shared/LocationsView';

import accountActions from 'actions/account';

export const AccountInfo = () => {
    const { account_id } = useParams();

    const hasSecondRowLocation = (location) => {
        return location.additional_street || location.street ? true : false;
    }

    const account = useSelector(state => state.account.account);

    const account_type = account?.account_type || '';

    const dispatch = useDispatch();

    const loading = useSelector(state => {
        const { isUpdatingContact, isReadingContact } = state.contact;
        return isUpdatingContact || isReadingContact;
    });

    const mainLocations = useSelector(state => (state.account.account?.locations.slice(0) || []).filter(location => location.main));
    const custom_data = useSelector(state => state.account.account?.custom_data || {});
    const fields = CustomGroupUtils.groupAndFlatten(custom_data).fields || {};

    const industryOptions = CustomGroupUtils.getFieldOptions(custom_data?.fields || [], 'industry_sector');

    const [industryValue, subIndustryValue] = `${fields?.industry_sector || ''}`?.split(',').map(value => Number(value)) || ['', ''];

    const industry = industryOptions.find(option => option.value === industryValue);

    const [fieldBeingEdited, setFieldBeingEdited] = useState(null);

    return (
        <Form>
            <Form.Field
                control='div'
            >
                <Grid style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <PhonesField
                        contact_id={account_id}
                        shouldCheckDuplicates={false}
                        noPadding
                        isEditing={fieldBeingEdited === "phones"} 
                        onCancelEdit={(cb) => {setFieldBeingEdited(null); cb();}} 
                        onEdit={() => {setFieldBeingEdited("phones")}}
                    />
                </Grid>
            </Form.Field>
            <Form.Field
                control='div'
            >
                <Grid style={{ paddingBottom: 10 }}>
                    <EmailsField
                        contact_id={account_id}
                        shouldCheckDuplicates={false}
                        noPadding
                        isEditing={fieldBeingEdited === "emails"} 
                        onCancelEdit={(cb) => {setFieldBeingEdited(null); cb();}} 
                        onEdit={() => {setFieldBeingEdited("emails")}}
                    />
                </Grid>
            </Form.Field>
            <Form.Field
                control='div'
                className='ContactInfo__locationField'
                style={{ width: '100%' }}
            >
                <Icon name='fas fa-location-dot' color='grey' className='ContactInfo__locationIcon' />
                <Popup
                    trigger={
                        <Label
                            size='medium'
                            className={`ContactInfo__locationLabel ContactInfo__locationLabel${mainLocations.length > 0 ? '--withArrow' : '--noDefault'}`}
                            style={{ maxWidth: '93%' }}
                        >
                            {mainLocations.length <= 0 && 'No default location'}
                            {mainLocations.map((mainLocation, index) => (
                                <div className='ContactInfo__locationContainer'>
                                            <span key={index} className='ContactInfo__locationSpan' style={{ overflowWrap: 'break-word', maxWidth: '85%' }}>
                                                <span className='ContactInfo__contact--address'>
                                                    <div className={`ContactInfo__locationFirstRow${hasSecondRowLocation(mainLocation) ? '--secondRow' : '--noSecondRow'}`}>
                                                        {mainLocation.additional_street ? `${mainLocation.additional_street} ` : ''}
                                                        {mainLocation.street ? `${mainLocation.street} ` : ''}
                                                    </div>
                                                    <div>
                                                        {mainLocation.city ? `${mainLocation.city}, ` : ''}
                                                        {mainLocation.state ? `${mainLocation.state} ` : ''}
                                                        {mainLocation.zip ? mainLocation.zip : ''}
                                                    </div>
                                                </span>
                                            </span>
                                    <div className='ContactInfo__caretContainer'>
                                        <Icon name='fa-solid fa-caret-down' size='small' className='ContactInfo__caretDown' />
                                    </div>
                                </div>
                            ))}
                        </Label>
                    }
                    content={(
                        <LocationsView
                            id={account_id}
                            loading={loading}
                            location_type={account_type === 'business' ? 'office' : 'home'}
                            mainLocationId={mainLocations[0]?.id}
                            refresh={() => {
                                dispatch(accountActions.readAccount(account_id));
                            }}
                            type={'account'}
                        />
                    )}
                    on='click'
                    size='tiny'
                    style={{ width: '100em', padding: 0 }}
                    position='bottom center' />
            </Form.Field>
            {(account_type === 'business' || account_type === 'household') && (
                <Form.Field
                    control='div'
                >
                    <Icon name='fas fa-globe' color='grey' />
                    {fields?.website ? <a href={StringUtils.formatLink(fields?.website)} target="_blank" rel="noreferrer">{fields?.website}</a> : 'No website'}
                </Form.Field>
            )}
            {account_type === 'business' && (
                <Grid className='RecordInfoAccount'>
                    <Grid.Row columns='equal'>
                        <Grid.Column>Industry/Sector</Grid.Column>
                        <Grid.Column>{industry?.text || 'None'}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns='equal'>
                        <Grid.Column>Sub Industry</Grid.Column>
                        <Grid.Column>{industry?.sub_options?.find(option => option.value === subIndustryValue)?.text || 'None'}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns='equal'>
                        <Grid.Column>Business Tags</Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Form>
    )
}
