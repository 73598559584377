import {
    ClearableCheckboxDropdown,
    Table,
} from "dyl-components";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
    Header,
    Pagination,
    Segment,
    Table as SemanticTable,
} from "semantic-ui-react";
import CategoryProductRow from "./CategoryProductRow";

const LIMIT = 25;

const CategoryProducts = ({ onFilter, queryParameters, opportunity_id, hasFilters, onChangePage }) => {
    const { products, isReadingCatalog, count } = useSelector((state) => ({
        products: state.product_catalog.catalog,
        isReadingCatalog: state.product_catalog.isReadingCatalog,
        count: state.product_catalog.count,
    }));

    const { control } = useFormContext();

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.CollapsibleRow isHeader>
                        <SemanticTable.HeaderCell width={4}>
                            Products
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            <ClearableCheckboxDropdown
                                text="Type"
                                name="product_type"
                                options={[
                                    {
                                        key: "good",
                                        value: "good",
                                        text: "Good",
                                    },
                                    {
                                        key: "service",
                                        value: "service",
                                        text: "Service",
                                    },
                                ]}
                                onChange={(_, { value }) => {
                                    onFilter(_, {
                                        name: "product_type",
                                        value,
                                    });
                                }}
                                value={queryParameters.product_type}
                                closeOnLoad={true}
                            />
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Pricing Schedule
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Price
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell>
                            Max Discount
                        </SemanticTable.HeaderCell>
                        <SemanticTable.HeaderCell
                            style={{ textAlign: "center" }}
                        >
                            Actions
                        </SemanticTable.HeaderCell>
                    </Table.CollapsibleRow>
                </Table.Header>
                <Table.Body>
                    <Controller
                        control={control}
                        name="cart"
                        render={({ field: { name, value: cart, onChange } }) =>
                            !isReadingCatalog &&
                            products.map((product) => {
                                const cartIndex = cart.findIndex((item) => item.id === product.id);
                                const isInCart = cartIndex !== -1;
                                const price_data = product.price_data;
                                const isRecurring = price_data?.model?.includes("recurring");
                                const isVolumeBased = price_data?.model?.includes("volume");
                                const activeFrequencies = Object.keys(price_data.price).filter(frequency => price_data.price[frequency]?.active);
                                const selectedPricingSchedule = isRecurring ? cart[cartIndex]?.pricing_schedule || activeFrequencies[0] : null;
                                const price = (product.price_data && !product.price_data.model?.includes("volume")) && (isInCart && isRecurring ? `$${product.price_data.price[cart[cartIndex].pricing_schedule].price}` : `$${product.price_data.min_price}`);

                                const onUpdateCart = () => {
                                    onChange({
                                        target: {
                                            name,
                                            value: isInCart
                                                ? cart.filter((item) => item.id !== product.id)
                                                : [
                                                    ...cart,
                                                    {
                                                        id: product.id,
                                                        quantity: !isVolumeBased ? 1 : (
                                                            !isRecurring ? price_data.price[0].start : (
                                                                price_data.price[selectedPricingSchedule].volumes[0].start
                                                            )
                                                        ),
                                                        ...(isRecurring
                                                            ? { pricing_schedule: activeFrequencies[0] }
                                                            : {}),
                                                    },
                                                ],
                                        },
                                    });
                                };
                                return (
                                    <CategoryProductRow
                                        key={product.id}
                                        opportunity_id={opportunity_id}
                                        product={product}
                                        selectedPricingSchedule={selectedPricingSchedule}
                                        isInCart={isInCart}
                                        onUpdateCart={onUpdateCart}
                                        price={price}
                                    />
                                );
                            })
                        }
                    />
                </Table.Body>
            </Table>
            {isReadingCatalog && <Segment basic loading />}
            {!isReadingCatalog && products.length === 0 && (
                <Segment textAlign="center" basic>
                    <Header as="h3">{hasFilters ? "No Results Found" : "No Products Created"}</Header>
                </Segment>
            )}
            {products.length > 0 && (
                <Segment textAlign="right" basic>
                    <Pagination
                        boundaryRange={0}
                        activePage={queryParameters.page}
                        ellipsisItem={null}
                        siblingRange={2}
                        totalPages={Math.ceil(count / LIMIT)}
                        onPageChange={(_, { activePage }) => {
                            onChangePage(activePage)
                        }}
                        disabled={isReadingCatalog}
                    />
                </Segment>
            )}
        </>
    );
};

export default CategoryProducts;
