import { ButtonLink, DropdownTimeInput, NestedDropdown } from "dyl-components";
import React from "react";
import { Controller } from "react-hook-form";
import { Form, Icon, Label } from "semantic-ui-react";
import { PhoneUtil, StringUtils } from "utils";

const Timeframe = ({ control, isReadingDestinations, destinationOptions, index, isDeleteVisible, onRemove, provided }) => {
    const PARENT_NAME = `timeframes[${index}]`;
    return (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className="CallRoutingRule__ruleContainer"
        >
            <div
                {...provided.dragHandleProps}
                className="CallRoutingRule___elementContainer"
            >
                    <Icon className="fa-solid fa-arrows-up-down-left-right CallRoutingRuleModal__greyIcon" size={"large"}/>
            </div>
            <Controller
                name={`${PARENT_NAME}.days`}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <Form.Select
                        name={name}
                        value={value}
                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                        placeholder='Select days'
                        options={PhoneUtil.DAYS_OPTIONS}
                        label="Days"
                        className="CallRoutingRules__timeframeField"
                        required
                        multiple
                        renderLabel={(item) => {
                            const { value: itemValue  } = item;
                            return <Label>
                                {StringUtils.capitalize(itemValue)}
                                {value.length > 1 && (
                                    <Icon 
                                        name="delete" 
                                        onClick={() => {
                                            onChange({target: {name, value: value.filter((day) => day !== itemValue)}})
                                        }}
                                    />
                                )}
                            </Label>
                        }}
                    />
                )}
            />
            <Controller
                name={`${PARENT_NAME}.start_time`}
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Field
                        control={DropdownTimeInput}
                        onChange={(_, { value }) => {
                            onChange({ target: { name, value } });
                        }}
                        placeholder='Select start time'
                        error={error?.message}
                        value={value}
                        label="Start Time"
                        required
                        className="CallRoutingRules__timeframeField"
                    />
                )}
            />
            <Controller
                name={`${PARENT_NAME}.end_time`}
                control={control}
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                    <Form.Field
                        control={DropdownTimeInput}
                        onChange={(_, { value }) => {
                            onChange({ target: { name, value } });
                        }}
                        placeholder='Select end time'
                        error={error?.message}
                        value={value}
                        label="End Time"
                        required
                        className="CallRoutingRules__timeframeField"
                    />
                )}
            />
            <Controller
                name={`${PARENT_NAME}.destination_parent`}
                control={control}
                render={({ field: { name: parentName, value: parentValue, onChange: onParentChange } }) => (
                    <Controller 
                        name={`${PARENT_NAME}.destination`}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            const { name: childName, value: childValue, onChange: onChildChange } = field;
                            return (
                                <Form.Field
                                    control={NestedDropdown}
                                    child_value={childValue}
                                    parent_value={parentValue}
                                    loading={isReadingDestinations}
                                    nested_options={destinationOptions}
                                    onChange={(_, { parent_value, child_value }) => {
                                        onParentChange({ target: { name: parentName, value: parent_value } });
                                        onChildChange({ target: { name: childName, value: child_value } });
                                    }}
                                    placeholder="Select destination"
                                    display_parent
                                    selection
                                    label="Destination"
                                    pointing='top'
                                    required
                                    error={error?.message}
                                    className="CallRoutingRules__timeframeField CallRoutingRules__destinationField"
                                />
                            )
                        }}
                    />
                )}
            />
            {isDeleteVisible && (
                <ButtonLink onClick={() => onRemove(index)} className="CallRoutingRule___elementContainer">
                    <Icon className="fa-solid fa-trash CallRoutingRuleModal__redIcon" />
                </ButtonLink>
            )}
        </div>
    )
}

export default Timeframe;
