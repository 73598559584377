import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import './index.scss'

const Extensions = () => {
    return <Grid>
        <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Grid.Column style={{ paddingTop: '1em', paddingRight: 0, paddingLeft: '0.5em' }}>
                <Menu secondary className='PhoneManagement_generalTabs'>
                    <Menu.Item name='Users' as={NavLink} to='users' />
                    <Menu.Item name='Company' as={NavLink} to='company' />
                </Menu>
                <Segment basic>
                    <Outlet />
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </Grid>;
}

export default Extensions;