import React from 'react';
import { Table, Person } from 'dyl-components';
import { PhoneUtil } from 'utils';

const DestinationGroupsSubrow = ({ destinations }) => {
    return (
        <Table.CollapsibleRowContent indented nested colSpan="6">
            <Table.Cell colSpan="3">
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell>Destination</Table.HeaderCell>
                            <Table.HeaderCell>Extension</Table.HeaderCell>
                            <Table.HeaderCell>Ring Duration</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {destinations.map(({type, user, label, ring_time}, index) => {
                            return <Table.Row>
                                <Table.Cell>
                                    {index + 1}.
                                </Table.Cell>
                                <Table.Cell>
                                    {type === "extension" && !!user && <Person username={`${user.first_name} ${user.last_name}`} isUsernameOnly />}
                                </Table.Cell>
                                <Table.Cell>
                                    {label}
                                </Table.Cell>
                                <Table.Cell>
                                    {PhoneUtil.RING_DURATION_OPTIONS.find((ring_duration) => ring_duration.key === ring_time)?.text}
                                </Table.Cell>
                            </Table.Row>
                        })}
                    </Table.Body>
                </Table>
            </Table.Cell>
        </Table.CollapsibleRowContent>
    )
}

export default DestinationGroupsSubrow