import React, { useContext } from "react";
import {
    Table,
    EllipsisDropdown,
    Notification,
    STATUS_TYPES,
    ControlledPopup,
} from "dyl-components";
import {
    DropdownItem,
    Dropdown,
    Icon,
    Checkbox,
    Header,
} from "semantic-ui-react";
import { BulkActionsContext } from "shared/context/BulkActionsProvider";

const ELLIPSIS_OPTIONS = [
    {
        id: 1,
        text: "Edit",
        icon: "fas fa-edit",
        onClick: () => {},
    },
    {
        id: 2,
        text: "Delete",
        icon: "fas fa-trash-alt",
        displaysPopup: true,
        popupProps: {
            header: "Are you sure?",
            onConfirm: async () => {
                try {
                    Notification.alert(
                        "Successfully deleted!",
                        STATUS_TYPES.SUCCESS,
                        true
                    );
                } catch (e) {
                    Notification.alert(
                        "Failed to delete",
                        STATUS_TYPES.ERROR,
                        true
                    );
                }
            },
        },
    },
];

const Row = ({ extension, typeCounts }) => {
    const [selectedExtensions, setSelectedExtensions] =
        useContext(BulkActionsContext);

    const isSelected = (extension_id) => {
        return selectedExtensions.includes(extension_id);
    };

    const onToggleExtension = (extension_id) => {
        const newSelectedExtensions = isSelected(extension_id)
            ? selectedExtensions.filter((id) => extension_id !== id)
            : [...selectedExtensions, extension_id];
        setSelectedExtensions(newSelectedExtensions);
    };

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox
                    checked={isSelected(extension.id)}
                    onChange={() => {
                        onToggleExtension(extension.id);
                    }}
                />
            </Table.Cell>
            <Table.Cell>{extension.extension}</Table.Cell>
            <Table.Cell>{extension.type}</Table.Cell>
            <Table.Cell>{extension.label}</Table.Cell>
            <Table.Cell>{extension.display_view ? "on" : "off"}</Table.Cell>
            <Table.Cell>{extension.pin}</Table.Cell>
            <Table.Cell>
                <Icon
                    name="fa-solid fa-music vpiceboxPin-info-icon"
                    color="primary"
                />
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    {ELLIPSIS_OPTIONS.map(
                        (
                            {
                                text,
                                icon,
                                displaysPopup,
                                to,
                                ...otherProps
                            },
                            idx
                        ) => {
                            if (idx === 1) {
                                return (
                                    <ControlledPopup
                                        header={
                                            <Header as="h4" textAlign="center">
                                                Are you sure?
                                            </Header>
                                        }
                                        trigger={
                                            <Dropdown.Item
                                                icon={icon}
                                                text={text}
                                                disabled={(extension?.type === 'conference_room' || extension?.type === 'parking_lot') && typeCounts[extension?.type] <= 1}
                                            />
                                        }
                                        onConfirm={() => {
                                            console.log("deleted");
                                        }}
                                        inverted
                                    />
                                );
                            }
                            return (
                                <DropdownItem
                                    key={idx}
                                    text={text}
                                    icon={icon}
                                    displaysPopup={displaysPopup}
                                    style={{ cursor: "pointer" }}
                                    {...otherProps}
                                />
                            );
                        }
                    )}
                </EllipsisDropdown>
            </Table.Cell>
        </Table.Row>
    );
};

export default Row;
