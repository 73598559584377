import React from 'react';
import { Grid, Segment } from "semantic-ui-react";
import { Button } from 'dyl-components';

const CustomOptions = ({ contactsList, addClick, isVisible }) => {
    return (
            isVisible && contactsList && contactsList.length &&
                <Segment
                    //  loading={loading}
                    className="select-control-segment"
                >
                    <Grid>
                        {contactsList.map((contact,id) => {
                            return (
                                <Grid.Row
                                    columns={"equal"}
                                    verticalAlign="middle"
                                >
                                    <Grid.Column>{contact.name}</Grid.Column>
                                    <Grid.Column>{contact.text}</Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <Button onClick={()=>addClick(contact)} disabled={contact.isSelected} >add</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        })}
                    </Grid>
                </Segment>
    );
};

export default CustomOptions;
