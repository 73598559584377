import { MODEL_SCHEDULE, getModelMapping } from "./MODEL_SCHEDULE";
import { formatRecurringPriceData, transformRecurringPricingDetails } from "./fixedRecurringProductSchema";
import { formatUsageBasedPriceData, transformUsageBasedPricingDetails } from "./usageBasedProductSchema";
import { formatVolumeRecurringPriceData, transformVolumeRecurringPricingDetails } from "./volumeBasedRecurringProductSchema";
import { getDefaultSupplyChainNetworkValuesFromPayload, formatSupplyChainNetworkValues } from "../supply_chain_network/helper";

const getPrices = (price_data) => {
    const modelScheduleMapping =
        MODEL_SCHEDULE[getModelMapping(price_data.model, price_data.schedule)];
    switch (modelScheduleMapping) {
        case MODEL_SCHEDULE.fixed:
            return { price: price_data.price };
        case MODEL_SCHEDULE.volume:
            return { price: price_data.volume_prices.map(({ end, ...price }) => ({
                ...price,
                ...(end ? { end } : {})
            })) };
        case MODEL_SCHEDULE.usage: {
            return formatUsageBasedPriceData(price_data)
        }
        case MODEL_SCHEDULE.recurring: {
            return formatRecurringPriceData(price_data);
        }
        case MODEL_SCHEDULE.volume_recurring: {
            return formatVolumeRecurringPriceData(price_data);
        }
        default:
            return {};
    }
};

const getModelPrice = (price_data) => {
    const model = price_data.model;
    switch (model) {
        case "fixed":
            return { price: price_data.price };
        case "volume":
            return { volume_prices: price_data.price };
        case "usage": {
            return transformUsageBasedPricingDetails(price_data);
        }
        case "recurring": {
            return transformRecurringPricingDetails(price_data);
        }
        case "volume_recurring": {
            return transformVolumeRecurringPricingDetails(price_data);
        }
        default:
            return {};
    }
};

export const getDefaultValuesFromReadingAProduct = product => {
    return {
        ...getDefaultSupplyChainNetworkValuesFromPayload(product),
        name: product?.name || "",
        product_type: product?.product_type || null,
        description: product?.description || "",
        image: null,
        status: product?.status || null,
        active_date: product?.active_date || null,
        product_category: {
            name: product?.product_category?.id || "",
            description: product?.product_category?.description || ""
        },
        price_data: product?.price_data ? {
            model: product.model,
            schedule: product.schedule,
            ...getModelPrice(product?.price_data)
        } : {
            model: null,
            schedule: null,
            price: null
        },
        variants: Object.keys(product.variants || {}).map(name => ({
            name,
            values: product.variants[name]?.map(value => ({
                ...value,
                additional_price: value.additional_price || ""
            }))
        })),
        addons: product?.addons.map(addon => addon.id) || [],
        fees: product?.fees?.map(fee => fee.id) || [],
        taxes: product?.taxes?.map(tax => tax.id) || [],
        max_discount: product?.max_discount || 0
    }
}

export const formatProductDetails = (data) => {
    const { price_data, product_category, variants, ...otherData } = data;
    const category = (() => {
        if (!product_category) {
            return null;
        }
        const { name: value } = product_category;
        if (!value) {
            return null;
        }
        if (typeof value === "number") {
            return {
                id: value,
            };
        }
        return {
            name: value,
            description: product_category.description,
        };
    })();
    return {
        ...otherData,
        ...formatSupplyChainNetworkValues(data),
        product_category: category,
        variants: variants.reduce((data, { name, values }) => {
            return {
                ...data,
                [name]: values.map(value => ({
                    ...value,
                    additional_price: value.additional_price || null
                }))
            }
        }, {}),
        price_data: {
            model: getModelMapping(price_data.model, price_data.schedule),
            ...getPrices(price_data),
        },
    };
};

