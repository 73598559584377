import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Pagination, Segment } from 'semantic-ui-react';
import { EmptyListPlaceholder, Table, TableLoader } from 'dyl-components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Toolbar from './subcomponents/Toolbar';
import Header from './subcomponents/Header';
import ProductCatalogRow from './subcomponents/ProductCatalogRow';

import './index.scss';
import productsActions from 'actions/products';

const ProductCatalog = () => {
    const LIMIT = 25;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const page = query.get("page") || 1;

    const { count, products, categories, isReadingProducts, isReadingCategoryProducts } = useSelector((state) => {
        return {
            count: state.products.count,
            categories: state.products.categories,
            products: state.products.products,
            isReadingProducts: state.products.isReadingProducts,
            isReadingCategoryProducts: state.products.isReadingCategoryProducts
        };
    })

    const getProductCatalogs = useCallback((params) => {
        return dispatch(productsActions.readProducts(params));
    }, [dispatch]);

    const getCategoryProducts = (params) => {
        return dispatch(productsActions.readCategoryProducts(params));
    }

    useEffect(() => {
        getProductCatalogs({
            page,
            limit: LIMIT
        });
    }, [page, dispatch, getProductCatalogs]);

    const onChangePage = (_, { activePage }) => {
        const queryParams = new URLSearchParams(query);
        queryParams.set("page", activePage);
        const queryString = queryParams.toString();
        navigate(`/settings/product-catalog-quotes-invoices/product-catalog${queryString ? `?${queryString}` : ""}`)
    };

    return (
        <div className='ProductCatalogTab'>
            <Grid>
                <Grid.Row>
                    <Grid.Column className='ProductCatalogTab__ToolbarColumn'>
                        <Toolbar />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Table striped={false} className="ProductCatalogTab__Table">
                        <Header />
                        <Table.Body>
                            {isReadingProducts && <TableLoader isLoading colspan={8} />}
                            {(!isReadingProducts && count === 0) &&
                                <Table.Row>
                                    <Table.Cell className='NoProductsDisplay' colspan={8}>
                                        <EmptyListPlaceholder
                                            title='No Products Added'
                                            name='Product'
                                            link=''
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            }
                            {!isReadingProducts && categories.map((category, index) =>
                                <ProductCatalogRow
                                    key={index}
                                    category={category}
                                    getCategoryProducts={getCategoryProducts}
                                />
                            )}
                            {!isReadingProducts && products.map((product, index) =>
                                <ProductCatalogRow
                                    key={index}
                                    product={product}
                                />
                            )}
                        </Table.Body>
                    </Table>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {count > 0 && (
                            <Segment basic textAlign="right">
                                <Pagination
                                    boundaryRange={0}
                                    activePage={page}
                                    ellipsisItem={null}
                                    siblingRange={2}
                                    totalPages={Math.ceil(count / LIMIT)}
                                    onPageChange={onChangePage}
                                    disabled={isReadingProducts || isReadingCategoryProducts}
                                />
                            </Segment>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default ProductCatalog;
