import React from 'react';
import { Icon } from 'semantic-ui-react';
//PERMISSION DISABLED
// import { PermissionUtils } from 'utils';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SearchBar, Button } from 'dyl-components';

const UserAccountsToolbar = ({ onOpenAddUser, isCreateUserDisabled, isReadingUserAccounts, isReadingRoles }) => {
    const navigate = useNavigate();
    const [ query ] = useSearchParams();
    const search = query.get('search') || '';

    const searchFunction = (value)=> navigate(`/settings/users?search=${value}`);
    const cancelFunction = ()=> navigate(`/settings/users`);
    
    return (
    <div className='UserOptions'>
        <SearchBar
            isSearching={ isReadingUserAccounts }
            search={ search }
            searchFcn={ searchFunction }
            cancelFcn={ cancelFunction }
            placeholder={ 'Search for a name or email address'}
        />
        <Button loading={isReadingRoles} className='UserOptions__NewUserButton' onClick={onOpenAddUser} disabled={isCreateUserDisabled || isReadingRoles}><Icon name='plus' /> New User</Button>
    </div>
);
}
const mapStateToProps = state => {
    //PERMISSION DISABLED
    // const { permissionCheckingProp, isAccessibleProp } = PermissionUtils;

    return ({
        //PERMISSION DISABLED
        // isCreateUserDisabled: state.auth[permissionCheckingProp(PERMISSIONS.USER_PERMISSION_CHECKERS.CREATE)] || !state.auth[isAccessibleProp(PERMISSIONS.USER_PERMISSION_CHECKERS.CREATE)],
        isCreateUserDisabled: false,
        isReadingUserAccounts: state.assign.isReadingUserAccounts,
        isReadingRoles: state.roles.isReadingRoles
    })
}

export default connect(mapStateToProps)(UserAccountsToolbar);
