import { ButtonLink, Modal, NestedDropdown, Notification, STATUS_TYPES, generateResolver } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Form, Header, Icon } from "semantic-ui-react";
import './index.scss'
import { useDispatch, useSelector } from "react-redux";
import { PhoneUtil } from "utils";
import pbxConfigActions from "actions/pbx_config";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Timeframe from "./subcomponents/Timeframe";

const defaultTimeframe = {
    days: [],
    start_time: null,
    end_time: null,
    destination_parent: null,
    destination: null
}

const Content = ({ onClose, onReload, isEditing }) => {
    const dispatch = useDispatch();

    const [destinationOptions, setDestinationOptions] = useState([]);

    const { isSavingCallRoutingRules, destinations, isReadingDestinations } = useSelector((state) => state.pbx_config);

    const { control, formState: { isValid, isDirty }, handleSubmit, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            routing_rule_name: "",
            holiday_schedule: null,
            after_hours: null,
            after_hours_parent: null,
            timeframes: [defaultTimeframe],
        },
        resolver: generateResolver({})
    });

    const { fields, append, remove, move } = useFieldArray({
        control,
        name: "timeframes"
    })

    const onAddTimeframe = () => append(defaultTimeframe);

    const onAdd = async (data) => {
        try {
            onReload();
            onClose();
            Notification.alert('Call Routing Rule added successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to add Call Routing Rule', STATUS_TYPES.ERROR);
        }
    }

    const onEdit = async (data) => {
        try {
            onReload();
            onClose();
            Notification.alert('Call Routing Rule updated successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update Call Routing Rule', STATUS_TYPES.ERROR);
        }
    }

    const onRemove = (index) => {
        remove(index);
    }

    const onDragEnd = (result) => {
        const { destination, source } = result;
        const { index: destinationIndex } = destination || {};
        const { index: sourceIndex } = source || {};
        if (sourceIndex !== undefined && destinationIndex !== undefined) {
            move(sourceIndex, destinationIndex);
        }
    }

    useEffect(() => {
        if (destinations) {
            const destinationsAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationsAux.map((destination) => {
                const { key } = destination; 
                const options = destinations[key]?.map(({destination_id, label}) => (
                    {key: destination_id, value: destination_id, text: label}
                )) || []
                return {...destination, options};
            })
            setDestinationOptions(destinationOptionsAux);
        }
    }, [destinations]);

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinations());
    }, [dispatch])

    return (
        <>
            <Modal.Header>
                {isEditing ? "Edit " : "Create "} Call Routing Rule
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={isSavingCallRoutingRules}>
                    <div className="CallRoutingRuleModal__form">
                        <div className="CallRoutingRuleModal__controllerContainer">
                            <Controller
                                name='routing_rule_name'
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Routing Rule Name"
                                        placeholder="Type routing rule name"
                                        required
                                        error={error?.message}
                                        className="CallRoutingRules__ruleField"
                                    />
                                )}
                            />
                            <Controller
                                name='holiday_schedule'
                                control={control}
                                style={{flex: 1}}
                                render={() => (
                                    <Form.Select
                                        readOnly
                                        label="Holiday Schedule"
                                        placeholder="Select holiday schedule"
                                        options={[]}
                                        className="CallRoutingRules__ruleField"
                                    />
                                )}
                            />
                            <Controller
                                name="after_hours_parent"
                                control={control}
                                render={({ field: { name: parentName, value: parentValue, onChange: onParentChange } }) => (
                                    <Controller 
                                        name="after_hours"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            const { name: childName, value: childValue, onChange: onChildChange } = field;
                                            return (
                                                <Form.Field
                                                    control={NestedDropdown}
                                                    child_value={childValue}
                                                    parent_value={parentValue}
                                                    loading={isReadingDestinations}
                                                    nested_options={destinationOptions}
                                                    onChange={(_, { parent_value, child_value }) => {
                                                        onParentChange({ target: { name: parentName, value: parent_value } });
                                                        onChildChange({ target: { name: childName, value: child_value } });
                                                    }}
                                                    placeholder="Select destination"
                                                    display_parent
                                                    selection
                                                    label="After Hours"
                                                    pointing='top'
                                                    required
                                                    error={error?.message}
                                                    className="CallRoutingRules__ruleField"
                                                />
                                            )
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="CallRoutingRuleModal__rulesContainer">
                            <Header as="h3" color="primary">
                                Timeframes
                            </Header>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable-1" type="RULE">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {fields.map((_, index) => {
                                                return <Draggable draggableId={`RULE-${index}`} index={index}>
                                                    {(provided) => (
                                                        <Timeframe 
                                                            control={control}
                                                            isReadingDestinations={isReadingDestinations}
                                                            destinationOptions={destinationOptions}
                                                            index={index}
                                                            isDeleteVisible={fields.length > 1}
                                                            onRemove={onRemove}
                                                            setValue={setValue}
                                                            onMove={move}
                                                            destinationCount={fields.length}
                                                            provided={provided}
                                                        />
                                                    )}
                                                </Draggable>
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <ButtonLink onClick={onAddTimeframe}>
                                <Icon className="fa-solid fa-plus CallRoutingRuleModal__blackIcon"/> Add New Timeframe
                            </ButtonLink>
                        </div>
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions 
                hasSaveButton
                onSave={handleSubmit(isEditing ? onEdit : onAdd)}
                saveDisabled={!isValid || !isDirty}
                saveOptions={{ loading: false }}
            />  
        </>
    )
}

const CallRoutingRuleModal = ({ open, onClose, onReload, isEditing }) => {
    return (
        <Modal open={open} onClose={onClose} size={"large"}>
            <Content onClose={onClose} onReload={onReload} isEditing={isEditing} />
        </Modal>
    )
}

export default CallRoutingRuleModal;
