import { Modal, Notification, STATUS_TYPES, Step } from "dyl-components";
import { useContext, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Form, Grid, Icon, Segment } from "semantic-ui-react";
import OrderSummary from "./OrderSummary";
import { useDispatch, useSelector } from "react-redux";
import account from "actions/account";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import paymentActions from "actions/payment";

import orderActions from "actions/order";
import { formatOrder } from "shared/schemas/cart/cartSchema";

const STEPS = [
    {
        icon: <Icon className="fas fa-box-dollar" size="large" />,
        title: "Order",
        active: true,
    },
    {
        icon: <Icon className="fas fa-file-invoice-dollar" size="large" />,
        title: "Checkout",
    },
];

const Order = ({ account_id, contact_id }) => {
    const {
        formState: { isValid },
        handleSubmit,
        control
    } = useFormContext();

    const order = useSelector((state) => state.order.order);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(account.readContactsForPinning(account_id));
        dispatch(paymentActions.getProfiles(account_id));
    }, [dispatch, account_id]);

    const { onCheckout, onOpenAddProducts, onViewOrder, quoteBuilderConfig } =
        useContext(QuoteBuilderContext);

    const { id: order_id } = quoteBuilderConfig;

    const onProceedToCheckout = async (data) => {
        try {
            await dispatch(
                orderActions.update(
                    account_id,
                    {
                        items: formatOrder(data.cart),
                        name: data.order_name,
                        status: data.order_status
                    },
                    null,
                    order_id
                )
            );
            if (!Boolean(contact_id)) {
                dispatch(orderActions.readRecentOrder(account_id));
            }
            onCheckout({ checkout: data });
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to proceed to checkout", STATUS_TYPES.ERROR);
        }
    };

    const isAllowedToEdit =
        order?.status === "open" ||
        order?.status === "sent" ||
        order?.status === "overdue" ||
        order?.status === "disputed" ||
        !Boolean(order?.status);

    const onCreateOrder = async (data) => {
        try {
            const order_id = await dispatch(
                orderActions.create(
                    {
                        items: formatOrder(data.cart),
                        name: `Duplicate of ${data.order_name}`
                    },
                    {
                        account_id,
                    }
                )
            );
            Notification.alert(
                "Successfully duplicated order",
                STATUS_TYPES.SUCCESS
            );
            onViewOrder(order_id, account_id);
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to duplicate order", STATUS_TYPES.ERROR);
        }
    };

    const isSaving = useSelector(
        (state) => state.order.isCreating || state.order.orderBeingUpdated
    );

    const { fields: cart, replace } = useFieldArray({
        control,
        name: "cart",
        keyName: "key",
    });

    return (
        <>
            <Modal.Content scrolling>
                <Form loading={isSaving} size="small" noValidate>
                    <Segment size="tiny" basic>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Step.Group horizontal>
                                        {STEPS.map(({ icon, ...step }) => (
                                            <Step {...step} key={step.name}>
                                                {icon}
                                            </Step>
                                        ))}
                                    </Step.Group>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <OrderSummary
                                        quote={{
                                            quote_summary: order.order_items,
                                        }}
                                        account_id={account_id}
                                        disabled={!isAllowedToEdit}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {isAllowedToEdit ? (
                    <>
                        <Button
                            basic
                            onClick={() => {
                                replace(cart.filter((item) => !Boolean(item.removed)));
                                onOpenAddProducts({
                                    order_id,
                                    isUpsell: true,
                                });
                            }}
                            type="button"
                            color="primary"
                            disabled={isSaving}
                        >
                            Back to Add Products
                        </Button>
                        <Button
                            disabled={!isValid || isSaving}
                            type="button"
                            color="primary"
                            onClick={handleSubmit(onProceedToCheckout)}
                            loading={isSaving}
                        >
                            Next
                        </Button>
                    </>
                ) : (
                    <Button
                        disabled={!isValid || isSaving}
                        type="button"
                        color="primary"
                        onClick={handleSubmit(onCreateOrder)}
                        loading={isSaving}
                    >
                        Create New Order
                    </Button>
                )}
            </Modal.Actions>
        </>
    );
};

export default Order;
