import React from 'react';
import { Grid, Icon, Form } from 'semantic-ui-react';
import { ButtonLink, ClippedContent, STATUS_TYPES } from 'dyl-components';

import { Controller } from 'react-hook-form';
import { STATES } from 'shared/constants/STATES';
import LocationUtils from 'utils/LocationUtils';

const RecordLocations = ({
    isEditing,

    locations,
    control,
    onRemove,
    addressLabelOptions = [],

    updateMain
}) => (
    <Grid stackable celled={isEditing} className='RecordInfo' columns='equal'>
        {locations.map((location, index) => !isEditing ? (
            <Grid.Row key={location.id} columns='equal'>
                <Grid.Column>
                    <div>
                        <b>{LocationUtils.getAddressLabel(location.label)}</b> {location.main && <Icon className='fas fa-star' color='primary' />}
                    </div>
                    <div className='RecordLocations__location'>
                        <div className='RecordLocations__location-line'>
                            <ClippedContent popup={false}>
                                {location.additional_street ? `${location.additional_street} ` : ''}
                                {location.street ? `${location.street} ` : ''}
                            </ClippedContent>
                        </div>
                        <div className='RecordLocations__location-line'>
                            <ClippedContent popup={false}>
                                {location.city ? `${location.city}, ` : ''}
                                {location.state ? `${location.state} ` : ''}
                                {location.zip || ''}
                            </ClippedContent>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        ) : (
            <Grid.Row key={location.id} columns='equal' verticalAlign='middle'>
                <Grid.Column verticalAlign='middle'>
                    <Form.Group widths='equal'>
                        <Controller
                            name={`locations[${index}].label`}
                            control={control}
                            render={({ field: { name, onChange, value } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Address Label'
                                    options={addressLabelOptions}
                                />
                            )}
                        />
                        <Controller
                            name={`locations[${index}].main`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Radio
                                    name={name}
                                    checked={value}
                                    onChange={(_, { checked: value }) => {
                                        updateMain(() => {
                                            onChange({ target: { name, value } });
                                        });
                                    }}
                                    label='Main'
                                />
                            )}
                        />
                    </Form.Group>
                    <Controller
                        name={`locations[${index}].street`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='Street Address'
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name={`locations[${index}].apartmentUnitOrFloor`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='Apt, unit building, floor #'
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name={`locations[${index}].city`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <Form.Input
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='City'
                                error={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name={`locations[${index}].state`}
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <Form.Select
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                placeholder='State'
                                search
                                options={STATES.map(state => ({ ...state, value: state.key }))}
                            />
                        )}
                    />

                    <Form.Group widths='equal'>
                        <Controller
                            name={`locations[${index}].zip`}
                            control={control}
                            render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                    placeholder='Zipcode'
                                    error={error?.message}
                                />
                            )}
                        />
                        <Form.Field>
                            <ButtonLink style={{ marginLeft: '-0.5em' }} size='small' status={STATUS_TYPES.ERROR} onClick={() => { onRemove(index); }}>
                                <Icon className='fas fa-trash-can' /> Delete
                            </ButtonLink>
                        </Form.Field>
                    </Form.Group>
                </Grid.Column>
            </Grid.Row>
        ))}
    </Grid>
);

export default RecordLocations;
