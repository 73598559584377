export default class ObjectUtils {
    static unflatten(flattenedObject) {
        const result = {}
        for (let i in flattenedObject) {
            let keys = i.split('|')
            keys.reduce(function (r, e, j) {
                return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? flattenedObject[i] : {}) : [])
            }, result)
        }
        return result;
    }

    static flatten(data, c) {
        var result = {}
        for (var i in data) {
            if (typeof data[i] == 'object') Object.assign(result, ObjectUtils.flatten(data[i], c + '|' + i))
            else result[(c + '|' + i).replace(/^\|/, "")] = data[i]
        }
        return result
    }

    static getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    static findValue(array, id){
        return array.find(value=>value.value === id)?.text;
    }

    static deepEqual(firstObject, secondObject) {
        return JSON.stringify(firstObject) === JSON.stringify(secondObject);
    }

    static entriesToObject(entries) {
        const result = {}
        for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
            result[key] = value;
        }
        return result;
    }

    static parseFieldOptions(groups) {
        if (!groups?.length) {
            return [];
        }
        return groups.map(group => {
            const { children, fields } = group;
            return [
                ...(children || []).map(child => {
                    const { fields: child_fields, label } = child;
                    return [
                        {
                            key: label,
                            text: label,
                            className: 'FieldCategory',
                            disabled: true,
                            header: true
                        },
                        ...child_fields?.map(child_field => {
                            const fieldOptions = child_field?.options || [];
                            const type = child_field.field_type;
                            const options = (fieldOptions && fieldOptions.length > 0) ? 
                                type === "dropdown" || type === "tagsinput" || type === "picklist" || type === "rating" ?
                                    fieldOptions.map((option, id) => ({ text: option.value, value: option.id || id, key: option.id || id})) || [] : 
                                type === "dependency" ?
                                    fieldOptions.map(parentOptions => ({
                                        key: parentOptions.id,
                                        value: parentOptions.id,
                                        text: parentOptions.value,
                                        options: (
                                            parentOptions?.sub_options?.map(type => ({
                                                key: type.id,
                                                value: type.id,
                                                text: type.value
                                            })) || []
                                        )
                                    })) || []
                                : false
                                    : false;
                            return {
                                key: child_field.flatten_path,
                                value: child_field.flatten_path,
                                text: child_field.label,
                                className: 'FieldCategory__option',
                                field_type: type,
                                options,
                                parent: label
                            }
                        })
                    ]
                }).flat(),
                ...fields.map(field => {
                    const fieldOptions = field?.options || [];
                    const type = field.field_type;
                    const options = (fieldOptions && fieldOptions.length > 0) ? 
                        type === "dropdown" || type === "tagsinput" || type === "picklist" || type === "rating" ?
                            fieldOptions.map((option, id) => ({ text: option.value, value: option.id || id, key: option.id || id })) || [] : 
                        type === "dependency" ?
                            fieldOptions.map(parentOptions => ({
                                key: parentOptions.id,
                                value: parentOptions.id,
                                text: parentOptions.value,
                                options: (
                                    parentOptions?.sub_options?.map(type => ({
                                        key: type.id,
                                        value: type.id,
                                        text: type.value
                                    })) || []
                                )
                            })) || []
                        : false
                            : false;
                    return {
                        key: field.flatten_path,
                        value: field.flatten_path,
                        text: field.label,
                        field_type: type,
                        options,
                        className: 'Field__option'
                    }
                })
            ]
        }).flat();
    }

    static formatFields(mapping_fields){
        if (mapping_fields === null || mapping_fields === undefined) {
            return [];
        }
        const { contact, lead, opportunity, customer } = mapping_fields;
        const contact_standard_fields = this.parseFieldOptions(contact.standard);
        const lead_standard_fields = this.parseFieldOptions(lead?.standard);
        const opportunity_standard_fields = this.parseFieldOptions(opportunity?.standard);
        const customer_standard_fields = this.parseFieldOptions(customer?.standard);

        return [
            {
                key: 'Standard Fields',
                text: 'Standard Fields',
                disabled: true,
                className: 'FieldGroupClassification',
                primary_header: true
            },
            ...contact_standard_fields,
            ...lead_standard_fields,
            ...opportunity_standard_fields,
            ...customer_standard_fields
        ];
    }

    static addMainField = (name, array) => {
        const headerIndex = array.findIndex(({header, text}) => header && text === name);
        array.splice(headerIndex + 1, 0, {
            key: `composite_${name.toLowerCase()}`,
            value: name.toLowerCase(),
            text: name,
            className: "FieldCategory__option",
            field_type: "composite",
            options: false,
            parent: name
        })
    }

    static extractCompositeFields(fields) {
        const phoneFields = [];
        const locationFields = [];
        const emailFields = [];
        const fieldsCopy = [];

        //TODO: Remove when CER-3999 is fixed
        for (let index = 0; index < fields.length; index++) {
            if (index === 0) {
                fieldsCopy.push(fields[0]);
                continue;
            }
            const isInCopy = fieldsCopy.find(({key}) => fields[index].key === key);
            if (!isInCopy) {
                fieldsCopy.push(fields[index]);
            }
        }
        
        const fieldOptions = fieldsCopy.filter((field) => {
            const { parent } = field;
            const isPhone = parent === "Phone";
            const isLocation = parent === "Location";
            const isEmail = parent === "Email";
            if (isPhone) {
                phoneFields.push(field);
            }
            if (isLocation) {
                locationFields.push(field);
            }
            if (isEmail) {
                emailFields.push(field);
            }
            return !isPhone && !isLocation && !isEmail;
        })
        this.addMainField("Phone", fieldOptions);
        this.addMainField("Location", fieldOptions);
        this.addMainField("Email", fieldOptions);
        return {
            fieldOptions,
            compositeFields: {
                phoneFields,
                locationFields,
                emailFields
            }
        }
    }
}
