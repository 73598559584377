import invoiceActions from "actions/invoice";
import paymentActions from "actions/payment";
import { Notification, STATUS_TYPES } from "dyl-components";
import { useContext, useEffect, useRef } from "react";
import { AcceptHosted } from "react-authorize-net";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Radio, Segment } from "semantic-ui-react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

const HostedForm = ({ form_token, account_id, onCancelPayment }) => {
    const payElement = useRef();
    const {
        formState: { isValid },
        handleSubmit,
    } = useFormContext();

    const paymentProfile = useSelector((state) =>
        state.payment.profiles ? state.payment.profiles[0]?.profile_id : null
    );

    useEffect(() => {
        payElement.current?.click();
    }, [payElement]);

    const dispatch = useDispatch();

    const { onViewInvoice, quoteBuilderConfig } =
        useContext(QuoteBuilderContext);
    const { id: order_id } = quoteBuilderConfig;

    const onProcessPayment = async (transactionResponse) => {
        return handleSubmit(async (data) => {
            if (isValid) {
                try {
                    const transId = transactionResponse.transId;
                    const profile_id = (async () => {
                        if (!Boolean(paymentProfile)) {
                            const id = await dispatch(
                                paymentActions.createCustomerProfile({
                                    transId,
                                })
                            );
                            await dispatch(
                                paymentActions.saveProfile(
                                    {
                                        default: true,
                                        processor: "authorize.net",
                                        profile_id: transId,
                                    },
                                    null,
                                    account_id
                                )
                            );
                            return id;
                        }
                        return paymentProfile;
                    })();
                    const { invoice_name, due_on, custom_ach, term } = data;
                    const invoice_id = await dispatch(
                        invoiceActions.create(
                            {
                                transaction_id: transId,
                                account_id,
                                payment_information_id: profile_id,
                                invoice_name,
                                due_on,
                                custom_ach,
                                term
                            },
                            null,
                            order_id
                        )
                    );
                    Notification.alert(
                        "Successfully processed payment!",
                        STATUS_TYPES.SUCCESS
                    );
                    onViewInvoice(invoice_id, account_id);
                } catch (e) {
                    console.log(e);
                    Notification.alert(
                        "Failed to process payment",
                        STATUS_TYPES.ERROR
                    );
                }
            } else {
                Notification.alert(
                    "One or more fields have errors",
                    STATUS_TYPES.ERROR
                );
            }
        })();
    };
    return (
        <AcceptHosted
            mode="sandbox"
            formToken={form_token}
            className="Checkout__payment"
            type="iframe"
            onTransact={onProcessPayment}
            onCancel={onCancelPayment}
        >
            <button style={{ display: "none" }} ref={payElement} color="black">
                Process
            </button>
        </AcceptHosted>
    );
};

const PaymentForm = ({ account_id, onCancelPayment }) => {
    const { isReadingFormToken, form_token } = useSelector((state) => ({
        isReadingFormToken: state.payment.isReadingFormToken,
        form_token: state.payment.token,
    }));
    return (
        <Segment size="mini" compact>
            {!isReadingFormToken ? (
                <HostedForm form_token={form_token} account_id={account_id} onCancelPayment={onCancelPayment} />
            ) : (
                <Segment loading basic />
            )}
        </Segment>
    );
};

const AuthorizeNetPaymentOption = ({
    isActive,
    name,
    label,
    onChangeSelectedPaymentMethod,
    account_id,
}) => {
    return (
        <>
            <Segment
                className={`PaymentMethod${
                    isActive(name) ? ` PaymentMethod--active` : ""
                }`}
                compact
            >
                <Radio
                    label={label}
                    checked={isActive(name)}
                    onChange={() => {
                        onChangeSelectedPaymentMethod(name);
                    }}
                />
            </Segment>
            {isActive(name) && <PaymentForm account_id={account_id} onCancelPayment={() => {onChangeSelectedPaymentMethod(null)}} />}
        </>
    );
};

const PaymentMethod = ({
    selectedPaymentMethod,
    onChangeSelectedPaymentMethod,
    account_id,
}) => {
    const isActive = (method) => {
        return selectedPaymentMethod === method;
    };

    const getClassName = (method) => {
        return `PaymentMethod${
            isActive(method) ? ` PaymentMethod--active` : ""
        }`;
    };

    const {
        formState: { isValid },
        control,
    } = useFormContext();

    return (
        <Segment.Group>
            <AuthorizeNetPaymentOption
                account_id={account_id}
                isActive={isActive}
                label={"Credit Card"}
                name={"cc"}
                onChangeSelectedPaymentMethod={onChangeSelectedPaymentMethod}
            />
            <AuthorizeNetPaymentOption
                account_id={account_id}
                isActive={isActive}
                label={"E-check"}
                name={"e-check"}
                onChangeSelectedPaymentMethod={onChangeSelectedPaymentMethod}
            />
            <Segment className={getClassName("custom-ach")} compact>
                <Radio
                    label="Custom (ACH)"
                    checked={isActive("custom-ach")}
                    onChange={() => {
                        onChangeSelectedPaymentMethod("custom-ach");
                    }}
                />
            </Segment>
            {selectedPaymentMethod === "custom-ach" && (
                <Segment style={{ minHeight: "11em" }}>
                    <Form noValidate>
                        <Controller
                            name="custom_ach"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <Form.TextArea
                                    placeholder="Insert account payable information (bank, account #)"
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                    error={error?.message}
                                />
                            )}
                        />
                        <Form.Button
                            type="button"
                            floated="right"
                            color="primary"
                            disabled={!isValid}
                        >
                            Process
                        </Form.Button>
                    </Form>
                </Segment>
            )}
        </Segment.Group>
    );
};

export default PaymentMethod;
