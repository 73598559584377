import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const getRecordings = generateReadActionCreator(routes.API_PBX_CONFIG_RECORDINGS, ACTION_NAMES.PBX_CONFIG_RECORDINGS);
const getDestinationGroups = generateReadActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUPS, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS);
const addDestinationGroups = generateCreateActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUPS, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS);
const deleteDestinationGroup = generateDeleteActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUP, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP);
const getDestinationGroup = generateReadOneActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUP, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP);
const editDestinationGroup = generateUpdateActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUP, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP);
const getDylNumbers = generateReadActionCreator(routes.API_PBX_CONFIG_DYLNUMBERS, ACTION_NAMES.PBX_CONFIG_DYLNUMBERS);
const getDylNumber = generateReadOneActionCreator(routes.API_PBX_CONFIG_DYLNUMBERS, ACTION_NAMES.PBX_CONFIG_DYLNUMBERS);
const getDestinations = generateReadActionCreator(routes.API_PBX_CONFIG_DESTINATIONS, ACTION_NAMES.PBX_CONFIG_DESTINATIONS);
const updateRecordings = generateUpdateActionCreator(routes.API_PBX_CONFIG_RECORDINGS, ACTION_NAMES.PBX_CONFIG_RECORDINGS);
const getPreferences = generateReadActionCreator(routes.API_PBX_CONFIG_PREFERENCES, ACTION_NAMES.PBX_CONFIG_PREFERENCES);
const updatePreferences = generateUpdateActionCreator(routes.API_PBX_CONFIG_PREFERENCES, ACTION_NAMES.PBX_CONFIG_PREFERENCES);
const updateDylNumber = generateUpdateActionCreator(routes.API_PBX_CONFIG_DYLNUMBERS, ACTION_NAMES.PBX_CONFIG_DYLNUMBERS);
const readCompanyExtensions = generateReadActionCreator(routes.API_PBX_CONFIG_COMPANY_EXTENSION, ACTION_NAMES.PBX_CONFIG_COMPANY_EXTENSION);
const listPhones = generateReadActionCreator(routes.API_PBX_LIST_PHONES, ACTION_NAMES.PBX_LIST_PHONES);
const getTimeDestinations = generateReadActionCreator(routes.API_PBX_CONFIG_TIME_DESTINATIONS, ACTION_NAMES.PBX_CONFIG_TIME_DESTINATIONS);

export default {
    getRecordings,
    updateRecordings,
    getPreferences,
    updatePreferences,
    getDylNumbers,
    getDylNumber,
    getDestinations,
    updateDylNumber,
    readCompanyExtensions,
    getDestinationGroups,
    addDestinationGroups,
    deleteDestinationGroup,
    getDestinationGroup,
    editDestinationGroup,
    listPhones,
    getTimeDestinations
}
