import React, { useContext } from 'react';
import { Table, EllipsisDropdown, ButtonLink } from 'dyl-components';
import { Checkbox, DropdownItem, Popup } from 'semantic-ui-react';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';

import '../index.scss';
import { PhoneUtil } from 'utils';

const Row = ({ callRoutingRule = {} }) => {
    const { id, label, phones, destination } = callRoutingRule;
    const [selectedCallRoutingRules, setSelectedCallRoutingRules] = useContext(BulkActionsContext);

    const isSelected = (routing_rule_id) => {
        return selectedCallRoutingRules.includes(routing_rule_id);
    }

    const onToggleNumber = (routing_rule_id) => {
        const newSelectedCallRoutingRules = isSelected(routing_rule_id) ? selectedCallRoutingRules.filter(id => routing_rule_id !== id) : [...selectedCallRoutingRules, routing_rule_id];
        setSelectedCallRoutingRules(newSelectedCallRoutingRules);
    }

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected(id)} onChange={() => { onToggleNumber(id) }} />
            </Table.Cell>
            <Table.Cell>
                <ButtonLink onClick={() => {}}>{label}</ButtonLink>
            </Table.Cell>
            <Table.Cell>
                <Popup
                    basic
                    on={"click"}
                    position='bottom center'
                    disabled={phones?.length <= 2}
                    trigger={
                        <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: '245px'}}>
                            {phones?.map((number, idx) => <span>{PhoneUtil.formatPhoneNumber(number)}{phones.length - 1 === idx ? "" : ", "}</span>)}
                        </div>
                    }
                    content={
                        <div style={{maxHeight: 128, overflow: "auto"}}>
                            {phones?.map((number, idx) => {
                                return <div style={{marginRight: 50, marginBottom: idx === phones.length - 1 ? 0 : 7}}>
                                    <span>{PhoneUtil.formatPhoneNumber(number)}</span>
                                </div>
                            })}
                        </div>
                    }
                />
            </Table.Cell>
            <Table.Cell>
                None
            </Table.Cell>
            <Table.Cell>
                {destination?.label}
            </Table.Cell>
            <Table.Cell>
                <EllipsisDropdown>
                    <DropdownItem
                        text={"Edit"}
                        icon={"fa-solid fa-pencil"}
                        onClick={() => {}}
                    />
                </EllipsisDropdown>      
            </Table.Cell>
        </Table.Row>
    );
}

export default Row;


