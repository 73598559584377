import React, { useState } from "react";
import {
    Modal,
    SearchableOptions,
    VALIDATORS,
    generateResolver,
    yup,
} from "dyl-components";
import { Controller, useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";
import CustomOptions from "./subcomponents/customOptions";
import { PhoneUtil } from "utils";
import "./index.scss";

const typeOptions = [
    {
        text: "Ring Group",
        value: "ringGroup",
    },
    {
        text: "Queue Call",
        value: "queueCall",
    },
];
const ringModeOptions = [
    {
        text: "Ring All",
        value: "ringAll",
    },
    {
        text: "Round Robin",
        value: "roundRobin",
    },
];

const noAnswerDestination = [
    {
        text: "Hang Up",
        value: "hangUp",
    },
    {
        text: "IVR Prompts",
        value: "iVRPrompts",
    },
    {
        text: "Ring Groups",
        value: "ringGroups",
    },
    {
        text: "Direct Extensions",
        value: "directExtensions",
    },
    {
        text: "VM Box",
        value: "vmBox",
    },
    {
        text: "Forwarding Numbers",
        value: "forwardingNumbers",
    },
];

const internalExtension = Array.from({ length: 100 }, (_, i) => {
    const value = (400 + i).toString();
    return { text: value, value: value };
});

const isloading = false;

const RingGroupModal = ({ open, setOpen }) => {
    let defaultData = {
        type: "ringGroup",
        name: "",
        ringMode: "",
        ringDuration: "",
        noAnswerDestination: "hangUp",
        audioWhileRinging: "",
        internalExtension: "",
        displayonOfficeView: "",
        selected_contacts: [],
    };

    let dummyContacts = [
        { id: 1, name: "Ext101", text: "Ext101", value: "Ext101" },
        { id: 2, name: "Ext102", text: "Lu Garcia", value: "Ext102" },
        { id: 3, name: "Ext103", text: "Ext103", value: "Ext103" },
    ];

    const [contacts, setContacts] = useState({
        data: dummyContacts,
        isOpen: false,
    });
    const [search, setSearch] = useState("");

    const {
        control,
        formState: { isValid, isDirty },
        getValues,
        handleSubmit,
        reset,
    } = useForm({
        mode: "onChange",
        defaultValues: defaultData,
        resolver: generateResolver({
            type: VALIDATORS.TEAM_NAME(true).required("This field is required"),
            name: VALIDATORS.TEAM_NAME(true).required("This field is required"),
            ringMode: VALIDATORS.TEAM_NAME(true).required(
                "This field is required"
            ),
            ringDuration: yup
                .number()
                .typeError("This field is required")
                .required("This field is required"),
            noAnswerDestination: VALIDATORS.TEAM_NAME(true).required(
                "This field is required"
            ),
        }),
    });

    const searchFunction = async () => {
        setContacts({
            data: [{ id: 2, name: "Ext102", text: "Lu Garcia" }],
            isOpen: true,
        }); // replace after api call
    };
    const cancelFunction = async () => {
        setContacts({ data: [], isOpen: false });
    };

    const callApiToGetContactOptions = () => {
        setContacts({
            data: [
                { id: 2, name: "Ext102", text: "Lu Garcia", value: "Ext102" },
                { id: 3, name: "Ext103", text: "Ext103", value: "Ext103" },
            ],
            isOpen: true,
        });
    };

    const checkSelectedContacts = (status, selected_contacts) => {
        const allContacts = [...contacts.data];
        const selectedContacts =
            selected_contacts ?? getValues("selected_contacts");
        allContacts.forEach((contact) => {
            if (!selectedContacts.length) contact.isSelected = false;
            selectedContacts.forEach((selected) => {
                if (selected === contact.id) {
                    contact.isSelected = status;
                }
            });
        });

        setContacts({ ...contacts, data: allContacts });
    };

    const onSubmit = () => {
        console.log(getValues(), "values");
        reset();
    };

    let isVisibleCustomContactOptions = Boolean(
        search || getValues("selected_contacts")?.length
    );

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Modal.Header>Create Ring Group/Call Queue</Modal.Header>
            <Modal.Content style={{ minHeight: "27rem" }}>
                <Form size="small" className="callQueue" loading={isloading}>
                    <Form.Group>
                        <Controller
                            name={`type`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Type"
                                    placeholder={`Select type`}
                                    selectOnBlur={false}
                                    options={typeOptions}
                                    width={4}
                                    required
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Controller
                            name={`name`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Input
                                    name={name}
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                    placeholder="Enter name"
                                    label="Name"
                                    width={4}
                                    required
                                />
                            )}
                        />
                        <Controller
                            name={`ringMode`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Ring Mode"
                                    placeholder={`Select Ring Mode`}
                                    selectOnBlur={false}
                                    options={ringModeOptions}
                                    width={4}
                                    required
                                />
                            )}
                        />
                        <Controller
                            name={`ringDuration`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Ring Duration"
                                    placeholder={`Select Ring Duration`}
                                    selectOnBlur={false}
                                    options={PhoneUtil.RING_DURATION_OPTIONS}
                                    width={4}
                                    required
                                />
                            )}
                        />
                        <Controller
                            name={`noAnswerDestination`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="No Answer Destination"
                                    placeholder={`Select Destination`}
                                    selectOnBlur={false}
                                    options={noAnswerDestination}
                                    width={4}
                                    required
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Controller
                            name={`audioWhileRinging`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Audio While Ringing"
                                    placeholder={`Select Audio`}
                                    selectOnBlur={false}
                                    options={[]}
                                    width={4}
                                />
                            )}
                        />
                        <Controller
                            name={`internalExtension`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <Form.Select
                                    name={name}
                                    value={value}
                                    search
                                    onChange={(_, { value }) => {
                                        onChange({
                                            target: { name, value },
                                        });
                                    }}
                                    label="Internal Extension"
                                    placeholder={`Select Extension`}
                                    selectOnBlur={false}
                                    options={internalExtension}
                                    width={4}
                                />
                            )}
                        />
                        <Controller
                            name="displayonOfficeView"
                            control={control}
                            render={({ field: { onChange, name, value } }) => {
                                return (
                                    <Form.Checkbox
                                        name={name}
                                        className="displayOnOfcClass"
                                        checked={value}
                                        toggle
                                        label="Display on Office View"
                                        onChange={(_, { checked }) => {
                                            onChange({
                                                target: {
                                                    name,
                                                    value: checked,
                                                },
                                            });
                                        }}
                                    />
                                );
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="extension-container">
                        <Controller
                            name={`selected_contacts`}
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <SearchableOptions
                                    label="Extension"
                                    // loading={loading}
                                    search={search}
                                    searchFunction={searchFunction}
                                    cancelSearchFunction={cancelFunction}
                                    searchPlaceholder={"Search for a contact"}
                                    width={8}
                                    values={value}
                                    onChangeSearch={(_, { value }) => {
                                        callApiToGetContactOptions();
                                        setSearch(value);
                                    }}
                                    onChangeSelected={(
                                        _,
                                        { value: removedContact }
                                    ) => {
                                        setSearch("");
                                        checkSelectedContacts(false, [
                                            removedContact,
                                        ]);
                                        onChange({
                                            target: {
                                                name,
                                                value: value.filter(
                                                    (item) =>
                                                        item !== removedContact
                                                ),
                                            },
                                        });
                                    }}
                                    // {onChangeSearch}
                                    // searchSubcomponent={hasPrimaryToggle && primaryContactToggler}
                                    isSearchResultsVisible={
                                        isVisibleCustomContactOptions
                                    }
                                    renderSearchResults={
                                        <CustomOptions
                                            addClick={(data) => {
                                                onChange({
                                                    target: {
                                                        name,
                                                        value: [
                                                            ...value,
                                                            data.id,
                                                        ],
                                                    },
                                                });
                                                checkSelectedContacts(true);
                                            }}
                                            contactsList={contacts.data}
                                            isVisible={
                                                isVisibleCustomContactOptions
                                            }
                                        />
                                    }
                                    isPaginationVisible={
                                        isVisibleCustomContactOptions &&
                                        contacts?.data?.length > 0
                                    }
                                    paginationProps={{
                                        // boundaryRange: 0,
                                        activePage: 1,
                                        ellipsisItem: null,
                                        siblingRange: 2,
                                        totalPages: 5,
                                        //  Math.ceil(state.count / LIMIT),
                                        // onPageChange: async (_, { activePage }) => {
                                        //     setState({ ...state, loading: true, open: true, page: activePage });
                                        //     const { data, count } = await onReadContacts({ page: activePage, search: state.search, limit: LIMIT });
                                        //     setState({ ...state, page: activePage, contact_options: data, count, loading: false, open: true });
                                        // }
                                    }}
                                />
                            )}
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>

            <Modal.Actions
                hasSaveButton
                onSave={handleSubmit(onSubmit)}
                saveDisabled={!isValid || !isDirty}
                saveOptions={{ loading: false }}
            />
        </Modal>
    );
};

export default RingGroupModal;
