import { Button, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import PaymentInformation from "./PaymentInformation";
import { Modal, Step } from "dyl-components";
import { Controller, useFormContext } from "react-hook-form";
import { useContext } from "react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import PaymentMethod from "./PaymentMethod";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import paymentActions from "actions/payment";
import { MathUtils, StringUtils } from "utils";
import HOSTED_PAYMENT_FORM_PREFERENCES from "shared/constants/HOSTED_PAYMENT_FORM_PREFERENCES";

const STEPS = [
    {
        icon: <Icon className="fas fa-box-dollar" size="large" />,
        title: "Order",
        completed: true,
    },
    {
        icon: <Icon className="fas fa-file-invoice-dollar" size="large" />,
        title: "Checkout",
        active: true,
    },
];

const Payment = ({ account_id }) => {
    const {
        formState: { control },
    } = useFormContext();
    const { onViewOrder, quoteBuilderConfig } = useContext(QuoteBuilderContext);

    const dispatch = useDispatch();

    const order = useSelector((state) => state.order.order);

    const onChangeSelectedPaymentMethod = (method) => {
        if (method !== "custom-ach") {
            const { cart, billing_address } = quoteBuilderConfig.checkout;
            const subtotal = cart.reduce((a, item) => {
                const productDetails = order.order_items.find(
                    (quoteItem) =>
                        quoteItem.product_id === item.id &&
                        quoteItem.product_variation_id === item.variation_id
                );
                const price = (() => {
                    const isOneTime =
                        !productDetails.price_data?.model?.includes(
                            "recurring"
                        );
                    const pricingModel = productDetails.price_data.model;
                    if (isOneTime) {
                        if (!pricingModel.includes("volume")) {
                            return pricingModel === "usage"
                                ? productDetails.price_data.price.price
                                : productDetails.price_data.price;
                        }
                        return (
                            productDetails.price_data.price.find(
                                (range) =>
                                    item.quantity >= range.start &&
                                    item.quantity <=
                                        (range.end || Number.POSITIVE_INFINITY)
                            )?.price || 0
                        );
                    }
                    if (!pricingModel.includes("volume")) {
                        return productDetails.price_data.price[
                            item.pricing_schedule
                        ].price;
                    }
                    const frequency =
                        productDetails.price_data.price[item.pricing_schedule]
                            .volumes;
                    return (
                        frequency.find(
                            (range) =>
                                item.quantity >= range.start &&
                                item.quantity <=
                                    (range.end || Number.POSITIVE_INFINITY)
                        )?.price || 0
                    );
                })();
                const { subtotal } = MathUtils.calculateItemTotals({
                    price,
                    additional_price:
                        productDetails.product_additional_price || 0,
                    quantity: item.quantity,
                    discount: 0,
                    addons: productDetails.addon
                        .filter(
                            (addon) =>
                                item.addons.includes(addon.id) &&
                                !addon.unlinked
                        )
                        .map((addon) => ({
                            price: addon.current_price || 0,
                        })),
                    taxes: productDetails.tax.map((tax) => ({
                        amount: tax.current_amount,
                        percent: tax.tax_fee_percent,
                    })),
                    fees: productDetails.fee.map((fee) => ({
                        amount: fee.current_amount,
                        percent: fee.tax_fee_percent,
                    })),
                });
                return a + subtotal;
            }, 0);
            const host = window.location.host;
            dispatch(
                paymentActions.generateFormToken({
                    transactionRequest: {
                        transactionType: "authCaptureTransaction",
                        amount: `${StringUtils.formatDecimal(
                            `${subtotal}`,
                            true
                        )}`,
                        customer: {
                            type:
                                order?.account?.type === "business"
                                    ? "business"
                                    : "individual",
                            id: account_id,
                            email: billing_address?.email,
                        },
                        billTo: (() => {
                            const {
                                street,
                                additional_street = "",
                                city,
                                state,
                                zip,
                            } = billing_address?.address;
                            return {
                                address: [street, additional_street]
                                    .filter((field) => field)
                                    .join(" "),
                                city,
                                state,
                                zip,
                                country: "US",
                                phoneNumber: billing_address?.phone,
                            };
                        })(),
                    },
                    hostedPaymentPreferences: [
                        ...HOSTED_PAYMENT_FORM_PREFERENCES,
                        {
                            settingName: "hostedPaymentPaymentOptions",
                            settingValue: `{"cardCodeRequired": false, "showCreditCard": ${
                                method === "cc" ? true : false
                            }, "showBankAccount": ${
                                method === "e-check" ? true : false
                            }}`,
                        },
                        {
                            settingName: "hostedPaymentReturnOptions",
                            settingValue: `{"showReceipt": false}`,
                        },
                        {
                            settingName: "hostedPaymentIFrameCommunicatorUrl",
                            settingValue: `{"url": "https://${
                                host === "localhost:3001"
                                    ? "dev.getdyl.com"
                                    : host
                            }/authorizenet_iframecommunicator.html"}`,
                        },
                    ],
                })
            );
        }
    };

    return (
        <>
            <Modal.Content scrolling>
                <Form size="small" noValidate>
                    <Segment size="tiny" basic>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Step.Group horizontal>
                                        {STEPS.map(({ icon, ...step }) => (
                                            <Step {...step} key={step.name}>
                                                {icon}
                                            </Step>
                                        ))}
                                    </Step.Group>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header color="primary">
                                        Invoice Information
                                    </Header>
                                    <PaymentInformation />
                                    <Header color="primary">
                                        Payment Method
                                    </Header>
                                    <Controller
                                        name="payment_method"
                                        control={control}
                                        render={({
                                            field: { name, value, onChange },
                                        }) => (
                                            <PaymentMethod
                                                selectedPaymentMethod={value}
                                                onChangeSelectedPaymentMethod={(method) => {
                                                    onChange({ target: { name, value: method } });
                                                    onChangeSelectedPaymentMethod(method);
                                                }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="buyer_notes"
                                        render={({
                                            field: { name, value, onChange },
                                        }) => (
                                            <Form.TextArea
                                                name={name}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({
                                                        target: { name, value },
                                                    });
                                                }}
                                                label="Buyer Notes"
                                            />
                                        )}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    basic
                    onClick={() => {
                        onViewOrder(quoteBuilderConfig?.id, account_id);
                    }}
                    type="button"
                    color="primary"
                >
                    Back to Order
                </Button>
            </Modal.Actions>
        </>
    );
};

export default Payment;
