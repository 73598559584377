import { Modal, generateResolver, yup, Notification, STATUS_TYPES, ToggableDropdown } from 'dyl-components';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Icon, Popup } from 'semantic-ui-react';
import contactsActions from 'actions/contacts';
import { BulkActionsContext } from "shared/context/BulkActionsProvider";
import { useSearchParams } from 'react-router-dom';
import groupsActions from 'actions/groups';
import campaignsActions from 'actions/campaigns';

const ContactBulkActionsModal = ({ open, onClose, numberSelectedContacts = 0 }) => {
    const [groupOptions, setGroupOptions] = useState([]);
    const [groupsSearch, setGroupsSearch] = useState('');
    const [query] = useSearchParams();
    const searchParam = query.get("search") || '';
    const account_type = query.get("account_type") || '';
    const start_time = query.get("start") || '';
    const end_time = query.get("end") || '';
    const CAMPAIGN_LIMIT = 999;

    const { control, reset, formState: { isValid, isDirty }, handleSubmit, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            type: '',
            group: ''
        },
        resolver: generateResolver({
            type: yup.string().required('This field is required'),
            group: yup.mixed().when('type', {
                is: 'move_contacts',
                then: schema => schema.required('This field is required')
            }),
        })
    });

    const { groups, subgroups, isReadingGroups, campaigns, isReadingCampaigns } = useSelector((state) => {
        return {
            groups: state.groups.groups,
            subgroups: state.groups.subgroups,
            isReadingGroups: state.groups.isReadingGroups,
            campaigns: state.campaigns.campaigns,
            isReadingCampaigns: state.campaigns.isReadingCampaigns
        }
    });

    const dispatch = useDispatch();

    const typeWatch = watch('type');

    const close = () => {
        reset();
        onClose();
    }
    
    const [selectedContacts,,areContactsInAllPagesSelected] = useContext(BulkActionsContext);

    const bulkActionOptions = [
        {
            key: 'add_to_hotlist', value: 'add_to_hotlist', text: 'Add to Hotlist', content: (
                <>
                    <Icon className={`fab fa-hotjar`} />
                    Add to Hotlist
                </>
            )
        },
        {
            key: 'add_to_campaign', value: 'add_to_campaign', text: 'Add to Campaign', content: (
                <>
                    <Icon className={`fas fa-megaphone`} />
                    Add to Campaign
                </>
            )
        },
        {
            key: 'add_to_contact_group', value: 'add_to_label', text: 'Add to Contact Group', content: (
                <>
                    <Icon className={`fas fa-folder`} />
                    Add to Contact Group
                </>
            )
        },
        {
            key: 'assign_pipeline', value: 'assign_pipeline', text: 'Assign Pipeline', content: (
                <>
                    <Icon className={`fas fa-filter`} />
                    Assign Pipeline
                </>
            )
        }
    ];

    const getMessages = (type) => {
        switch (type) {
            case "add_to_hotlist":
                return {
                    successMessage: "Successfully added to Hotlist!",
                    errorMessage: "Failed to add to Hotlist"
                }
            case "add_to_label":
                return {
                    successMessage: "Successfully added to group!",
                    errorMessage: "Failed to add to group"
                }
            case "add_to_campaign":
                return {
                    successMessage: "Successfully added to campaign!",
                    errorMessage: "Failed to add to campaign"
                }
            default: {}
        }
    }

    const onBulkAction = async ({type, group, campaign}) => {
        const { successMessage, errorMessage } = getMessages(type);
        try {
            await dispatch(contactsActions.contactBulkAction(
                { action: type, contact_id: areContactsInAllPagesSelected ? [] : selectedContacts, new_label_id: group?.value || null, campaign_id: campaign },
                { search: searchParam, account_type, start_time, end_time }
            ));
            close();
            Notification.alert(successMessage, STATUS_TYPES.SUCCESS);
        } catch (error) {
            console.log(error);
            Notification.alert(errorMessage, STATUS_TYPES.ERROR);
        }
    }

    const handleSearchChange = (e, { searchQuery }) => {
        setGroupsSearch(searchQuery);
    }

    const getGroups = useCallback(async (search) => {
        await dispatch(groupsActions.readGroups({search, limit: 500}));
    }, [dispatch])

    const getSubgroups = async (parent_label_id) => {
        await dispatch(groupsActions.readSubgroups({parent_label_id}));
    }

    const getCampaigns = useCallback(async () => {
        dispatch(campaignsActions.readCampaigns({limit: CAMPAIGN_LIMIT}));
    }, [dispatch])

    useEffect(() => {
        getGroups(groupsSearch);
    }, [groupsSearch, getGroups])

    useEffect(() => {
        getCampaigns();
    }, [getCampaigns])

    useEffect(() => {
        const groupOptionsAux = groups.map((group) => {
            let subgroupsAux = [];
            if (subgroups[group.id] && subgroups[group.id].length > 0) {
                subgroupsAux = subgroups[group.id].map((subgroup) => ({text: subgroup.name, key: subgroup.id, value: subgroup.id}));
            }
            return {text: group.name, key: group.id, value: group.id, hasOptions: group.sub_labels, options: subgroupsAux, parent: group.parent_label_name || null};
        })
        setGroupOptions(groupOptionsAux);
    }, [groups, subgroups])
    
    return (
        <Modal open={open} onClose={close}>
            <Modal.Header>
                Bulk Action <div style={{ float: 'right', marginRight: '1em' }}>
                    <small>{numberSelectedContacts} selected</small>
                    <Popup
                        trigger={<Icon size='small' style={{ marginLeft: '1em' }} floated='right' className='fas fa-circle-info' color='primary' />}
                        content={`Duplicates will be ignored`}
                        inverted
                        position='left center'
                        wide
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <Form noValidate>
                    <Controller
                        name='type'
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.Select
                                {...value ? { text: bulkActionOptions.find(action => action.value === value)?.content } : {}}
                                label='Bulk Action Type'
                                placeholder='Select bulk action type'
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                value={value}
                                options={bulkActionOptions}
                                required
                                error={error?.message}
                                width={8}
                                selectOnBlur={false}
                            />
                        )}
                    />
                    {typeWatch === "add_to_label" && (
                        <Controller
                            name='group'
                            control={control}
                            render={({ field: { name, value, onChange }}) => (
                                <Form.Field
                                    control={ToggableDropdown}
                                    value={value}
                                    nested_options={groupOptions}
                                    onChange={( _, { value }) => {
                                        onChange({ target: { name, value }});
                                    }}
                                    placeholder='Search Group Name'
                                    label='Group/Subgroup Name'
                                    onHoverParent={getSubgroups}
                                    loading={isReadingGroups}
                                    onSearchChange={handleSearchChange}
                                    searchValue={groupsSearch}
                                    resetSearchValue={() => setGroupsSearch("")}
                                    width={8}
                                    selection
                                    required
                                />
                            )}
                        />
                    )}
                    {typeWatch === "add_to_campaign" && (
                        <Controller 
                            name='campaign'
                            control={control}
                            render={({ field: { name, value, onChange }}) => (
                                <Form.Select
                                    value={value}
                                    options={campaigns.filter((campaign) => !campaign.deleted).map((campaign) => ({key: campaign.id, text: campaign.name, value: campaign.id}))}
                                    onChange={( _, { value }) => {
                                        onChange({ target: { name, value }});
                                    }}
                                    placeholder={
                                        <div>
                                            <Icon
                                                style={{ marginRight: 10 }}
                                                name="search"
                                            />
                                            Select an Existing Campaign Name
                                        </div>
                                    }
                                    label='Campaign Name'
                                    loading={isReadingCampaigns}
                                    search
                                    width={15}
                                    selection
                                    required
                                    selectOnBlur={false}
                                />
                            )}
                        />
                    )}
                </Form>
            </Modal.Content>
            <Modal.Actions
                hasSaveButton
                saveDisabled={!isValid || !isDirty}
                onSave={handleSubmit(onBulkAction)}
            />
        </Modal>
    )
}

export default ContactBulkActionsModal;
