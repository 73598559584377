import React, { useEffect, useState } from 'react';

import { ConfirmationPrompt, FilterableList, Modal, Notification, STATUS_TYPES, generateResolver, yup } from 'dyl-components';

import SequenceTimeline from './SequenceTimeline';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import sequencesActions from 'actions/sequences';
import sequenceTasksActions from 'actions/sequence_tasks';
import { useParams } from 'react-router-dom';
import { Dimmer, Loader, Segment, Modal as SemanticModal } from 'semantic-ui-react';
import contactSequenceActions from 'actions/contact_sequence';
import RescheduleSequenceModal from 'shared/modals/RescheduleSequenceModal';

const Sequences = () => {
    const { contact_id } = useParams();

    const { sequences, selectedSequenceTasks, selectedSequenceId, selectedSequenceName, isReadingSequenceContactTasks, isRemovingSequence, isAddingContactToSequence, pipeline_id } = useSelector((state) => ({
        sequences: state.sequences.sequencesShort || [],
        selectedSequenceTasks: state.sequence_tasks.selectedSequenceTasks,
        selectedSequenceId: state.sequence_tasks.selectedSequenceId,
        selectedSequenceName: state.sequence_tasks.selectedSequenceName,
        isReadingSequenceContactTasks: state.sequence_tasks.isReadingSequenceContactTasks,
        isRemovingSequence: state.contact_sequence.isRemovingSequence,
        isAddingContactToSequence: state.sequences.isAddingContactToSequence,

        pipeline_id: state.contact.current_stage?.pipeline_id,
    }));

    const { control, handleSubmit, setValue, reset, formState: { isValid, isDirty } } = useForm({
        mode: 'onChange',
        defaultValues: {
            sequence: ''
        },
        resolver: generateResolver({
            sequence: yup.string().required('This field is required')
        })
    });

    const dispatch = useDispatch();

    const [isCollapsed, setIsCollapsed] = useState(false);

    const onToggleCollapsed = () => {
        setIsCollapsed(!isCollapsed);
    }

    const refresh = () => {
        dispatch(sequenceTasksActions.readContactTasks(contact_id));
    }

    const onAddContact = async (data) => {
        const { sequence } = data;
        try {
            await dispatch(sequencesActions.addToSequence({ contact_id: Number(contact_id) }, null, sequence));
            Notification.alert('Sequence successfully added!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to add sequence!', STATUS_TYPES.ERROR);
        }
    }

    const onCancelTask = async (id) => {
        try {
            await dispatch(sequenceTasksActions.complete(id, { status: "canceled" }));
            Notification.alert('Successfully canceled task!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to cancel task!', STATUS_TYPES.ERROR);
        }
    }

    const onCompleteTask = async (id) => {
        try {
            await dispatch(sequenceTasksActions.complete(id, { status: "completed" }));
            Notification.alert('Successfully completed task!', STATUS_TYPES.SUCCESS);
            refresh();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to complete task!', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        setValue("sequence", selectedSequenceId || '');
    }, [selectedSequenceId, setValue])

    useEffect(() => {
        dispatch(sequencesActions.readShort({ limit: 2500 }));
        dispatch(sequenceTasksActions.readContactTasks(contact_id))
    }, [contact_id, dispatch]);

    const [isConfirmingRemoval, setIsConfirmingRemoval] = useState(false)

    const onCancelRemoval = () => { setIsConfirmingRemoval(false); };

    const onRemoveContact = async () => {
        try {
            await dispatch(contactSequenceActions.removeFromContact(Number(contact_id), { pipeline_id }));
            Notification.alert('Sequence successfully removed!', STATUS_TYPES.SUCCESS);
            onCancelRemoval();
            refresh();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to remove sequence!', STATUS_TYPES.ERROR);
        }
    }

    const [sequenceTaskBeingRescheduled, setSequenceTaskBeingRescheduled] = useState(null);

    return (
        <FilterableList
            header={<div>Sequences</div>}
            onToggleCollapsed={onToggleCollapsed}
            listComponent={
                <Segment loading={isReadingSequenceContactTasks || isRemovingSequence || isAddingContactToSequence} basic style={{ padding: 0 }}>
                    {!isReadingSequenceContactTasks && (
                        <SequenceTimeline
                            control={control}
                            sequenceOptions={sequences.map((sequence) => ({ key: sequence.id, value: sequence.id, text: sequence.name }))}
                            onSave={handleSubmit(onAddContact)}
                            onComplete={onCompleteTask}
                            selectedSequenceTasks={selectedSequenceTasks}
                            selectedSequenceId={selectedSequenceId}
                            selectedSequenceName={selectedSequenceName}
                            clearSequence={reset}
                            onCancel={onCancelTask}
                            canApplySequence={isValid && isDirty}
                            onRemove={() => { setIsConfirmingRemoval(true) }}
                            onSelectSequenceTaskForRescheduling={(taskId) => { setSequenceTaskBeingRescheduled(taskId) }}
                        />
                    )}
                    <SemanticModal className='ConfirmModal' size='mini' open={isConfirmingRemoval} onClose={onCancelRemoval}>
                        <Modal.Content>
                            <Dimmer active={isRemovingSequence}>
                                <Loader active={isRemovingSequence} inverted />
                            </Dimmer>
                            <ConfirmationPrompt header={'Are you sure?'} subheader={'All upcoming sequence tasks will be removed'} onCancel={onCancelRemoval} onConfirm={onRemoveContact} />
                        </Modal.Content>
                    </SemanticModal>
                    <RescheduleSequenceModal
                        open={sequenceTaskBeingRescheduled}
                        sequenceTaskId={sequenceTaskBeingRescheduled}
                        onClose={() => { setSequenceTaskBeingRescheduled(null) }}
                        refresh={refresh}
                    />
                </Segment>
            }
            isCollapsed={isCollapsed}
        />
    )
}


export default Sequences;
