import React, { useState, useEffect } from "react";
import { RecordsTable, Button } from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import Header from "./subcomponents/Header";
import Row from "./subcomponents/Row";
import { SearchBar } from "dyl-components";
import { Icon } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BulkActionsProvider from "shared/context/BulkActionsProvider";
import pbxConfigActions from "actions/pbx_config";
import "../index.scss";

const Company = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchQuery = params.get("search") || "";

    const { companyExtensions, isReadingCompanyExtensions } = useSelector(
        (state) => state.pbx_config
    );

    const [typeCounts, setTypeCounts] = useState({});
    const [filters, setFilters] = useState({
        type: params.get("type")?.split(","),
        status: params.get("order")?.split(","),
        shared: params.get("page")?.split(","),
    });

    useEffect(() => {
        dispatch(pbxConfigActions.readCompanyExtensions(filters));
    }, [filters, dispatch]);

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set("page", 1);

        const { type, order, page, search } = filters;

        if (search) {
            query.set("search", search);
        } else {
            query.delete("search");
        }

        if (type?.length > 0) {
            query.set("type", type.join(","));
        } else {
            query.delete("type");
        }

        if (order) {
            query.set("order", order);
        } else {
            query.delete("order");
        }

        if (page) {
            query.set("page", page);
        } else {
            query.delete("page");
        }

        const query_string = query.toString();
        navigate(
            `/settings/phone-management/extensions/company${
                query_string ? `?${query_string}` : ""
            }`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        const types = companyExtensions.data?.reduce((extension, { type }) => {
            extension[type] = (extension[type] || 0) + 1;
            return extension;
        }, {});
        setTypeCounts(types || {});
    }, [companyExtensions]);

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    const onPageChange = (_, { activePage }) => {
        onFilter(_, { name: "page", value: activePage });
    };

    return (
        <div className="extensionCompanyTable">
            <div className="Extensions">
                <SearchBar
                    isSearching={false}
                    search={searchQuery}
                    searchFcn={(value) => {
                        onFilter(null, { name: "search", value: value });
                    }}
                    cancelFcn={() => {
                        onFilter(null, { name: "search", value: null });
                    }}
                    placeholder={"Search by name or  extension"}
                />
                <div className="Extensions__ButtonGroup">
                    <Button className="Extensions__NewUserButton" disabled>
                        Bulk Actions
                    </Button>
                    <Button className="Extensions__NewUserButton">
                        <Icon name="plus" /> New
                    </Button>
                </div>
            </div>

            <RecordsTable
                tableBodyClassName="ExtensionsCompanyTable"
                isTableLoading={isReadingCompanyExtensions}
                isSearchResultEmpty={
                    !companyExtensions.count && params.get("search")
                }
                isTableEmpty={!companyExtensions.count && !params.get("search")}
                TableHeader={
                    <Header
                        extensions={companyExtensions}
                        onFilter={onFilter}
                    />
                }
                TableRows={companyExtensions.data?.map((extension) => (
                    <Row extension={extension} typeCounts={typeCounts} />
                ))}
                recordsCount={companyExtensions.count}
                activePage={params.get("page")}
                onPageChange={onPageChange}
                recordsName="Company Extension"
                emptySubtitle=" "
                emptyListTitle={"No Company Extensions Created"}
            />
        </div>
    );
};

export default () => (
    <BulkActionsProvider>
        <Company />
    </BulkActionsProvider>
);
