import { AndOrToggle, DateInput, InnerRuleContainer, NestedDropdown } from 'dyl-components';
import { ASSIGNMENTS_OPERATORS_OPTIONS } from 'pages/DataMapping/DYLFieldsConstants';
import { Controller } from 'react-hook-form';
import { Form } from 'semantic-ui-react';

export const Condition = ({ condition, parentName, name, index, isLast, control, trigger, watch }) => {
    const { type, text, options: fieldOptions } = condition;

    const watchedOperator = watch(`${name}[${index}].operator`)

    return (
        <div className={`${isLast ? "AssignmentCondition__container--last": "AssignmentCondition__container"}`}>
            <div className='AssignmentCondition__numberedContainer'>
                <div className='AssignmentCondition__numberContainer'>
                    <span className='AssignmentCondition__number'>{index + 1}</span>
                </div>
            </div>
            <div className='AssignmentCondition__conditionContainer'>
                <InnerRuleContainer isLast={isLast}>
                    <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                        <span style={{flex: 1}}>Field: {text}</span>
                        <Controller
                            control={control}
                            name={`${name}[${index}].operator`}
                            render={({ field: { onChange, value, name } }) => (
                                <Form.Select
                                    className='AssignmentRules__operatorField'
                                    label='Operator'
                                    value={value}
                                    name={name}
                                    onChange={(_, { name, value }) => {
                                        onChange({ target: { name, value } })
                                        trigger();
                                    }}
                                    options={ASSIGNMENTS_OPERATORS_OPTIONS[type]}
                                />
                            )}
                        />
                        <div style={{flex: 2}}/>
                    </div>
                    {watchedOperator !== "exists" && watchedOperator !== "not_exists" && (
                        <Controller
                            control={control}
                            name={`${name}[${index}].${type === "dependency" ? "parent_value" : "value"}`}
                            render={({ field: { onChange, value, name: fieldName }, fieldState: { error }  }) => {
                                switch (type) {
                                    case "text":
                                    case "phone":
                                    case "email":
                                    case "url":
                                        return (
                                            <Form.Input
                                                label='Values'
                                                name={fieldName}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name: fieldName, value } })
                                                }}
                                                error={error && error?.message && ({
                                                    content: error.message,
                                                    pointing: 'below'
                                                })}
                                            />
                                        )
                                    case "dropdown":
                                    case "tagsinput":
                                    case "rating":
                                        return (
                                            <Form.Select
                                                label='Values'
                                                value={value}
                                                name={fieldName}
                                                onChange={(_, { name, value }) => {
                                                    onChange({ target: { name, value } })
                                                }}
                                                options={fieldOptions}
                                                error={error && error?.message && ({
                                                    content: error.message,
                                                    pointing: 'below'
                                                })}
                                            />
                                        )
                                    case "dependency":
                                        return <Controller
                                            name={`${name}[${index}].child_value`}
                                            control={control}
                                            render={({ field: { name: childName, value: childValue, onChange: childOnChange }, fieldState: { error: childError } }) => {
                                                return (
                                                    <Form.Dropdown
                                                        control={NestedDropdown}
                                                        nested_options={fieldOptions}
                                                        onChange={(_, { parent_value, child_value: child }) => {
                                                            onChange({ target: { name: fieldName, value: parent_value } });
                                                            childOnChange({ target: { name: childName, value: child } });
                                                        }}
                                                        child_value={childValue}
                                                        parent_value={value}
                                                        display_parent
                                                        allowedToSelectParent
                                                        error={(error && error?.message && ({
                                                            content: error.message,
                                                        })) || (childError && childError?.message && ({
                                                            content: childError.message,
                                                        }))}
                                                    />
                                                )
                                            }}
                                        />
                                    case "date":
                                        return (
                                            <Form.Field
                                                control={DateInput}
                                                name={fieldName}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name: fieldName, value } })
                                                }}
                                                label='Values'
                                                error={error && error?.message && ({
                                                    content: error.message,
                                                    pointing: 'below'
                                                })}
                                            />
                                        )
                                    default:
                                        return (
                                            <Form.Input
                                                label='Values'
                                                name={fieldName}
                                                value={value}
                                                onChange={(_, { value }) => {
                                                    onChange({ target: { name: fieldName, value } })
                                                }}
                                                error={error && error?.message && ({
                                                    content: error.message,
                                                    pointing: 'below'
                                                })}
                                            />
                                        )
                                }
                            }}
                        />
                    )}
                </InnerRuleContainer>
                {!isLast && (
                    <Controller
                        control={control}
                        name={`${parentName}.boolean_operator`}
                        render={({ field: { onChange, value, name } }) =>
                            <AndOrToggle
                                name={name}
                                options={[
                                    { key: 'and', text: 'And', value: 'and'},
                                    { key: 'or', text: 'Or', value: 'or' },
                                ]}
                                value={value}
                                onChange={(value) => {
                                    onChange({ target: { name, value } })
                                }}
                            />
                        } 
                    />
                    
                )}
            </div>
        </div>
    )
}
