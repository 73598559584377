import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PhoneUtil } from 'utils';



const Destinations = ({ destination = {} }) => {
    const { label, type, user_id, username } = destination;

    return <>
        <div>
            <Icon className={PhoneUtil.DESTINATION_TYPES_ICONS[type]} style={{marginRight: 5}}/>
            {user_id && username && <Link to={`/settings/users/${user_id}/general`}>{username}</Link>}
            {!user_id && !username && (
                type === "extension" ? label : <Link to={PhoneUtil.DESTINATION_TYPES_URLS[type]}>{label}</Link>
            )}
        </div>
        {user_id && username && (
            <div>
                {label}
            </div>
        )}
    </>
}

export default Destinations;