import Centrifuge from 'centrifuge';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'actions/routes';
import basePath from 'actions/basePath';
import notificationsActions from 'actions/notifications';
import callActions from 'actions/call';
import officeViewActions from 'actions/office_view';
import permissionsActions from 'actions/permissions';
import userActions from 'actions/user';
import authActions from 'actions/auth/auth';
import roleActions from 'actions/role';
import { NotificationInfo } from 'dyl-components';
import axiosInstance from 'actions/axiosInstance';
import NotificationPopupModals from "shared/modals/NotificationPopUpModals";
import SendEmailModal from "shared/modals/SendEmailModal";
import AddTaskModal from "shared/modals/AddTaskModal";
import AddEventModal from "shared/modals/AddEventModal";

const CHANNELS = {
    permission: 'perm',
    chat: 'chat',
    notification: 'notify',
    voip: 'voip',
}

const WebsocketConnection = () => {
    const dispatch = useDispatch();
    const callDetails = useSelector(state => state.call.callDetails);
    const centrifuge = new Centrifuge(basePath.VIEW_HOST, {
        debug: true
    });

    useEffect(() => {
        const wsConnection = async () => {
            let myToken;
            const { user_id, customer_id, ws_token } = await dispatch(authActions.check()) || {};
            //check whether we persit conneciton token info
            if(!ws_token){
                try {
                    const tokenInfo = await axiosInstance.instance1.get(`${routes.WEBSOCKET}/connect`)
                    myToken = tokenInfo.data.feToken;
                } catch(e){
                    console.log("|+|Websocket Connection Failure",e)
                }
            } else {
                 myToken = ws_token;
            }
            
            centrifuge.setToken(myToken);
            centrifuge.on('connect', function (context) {
                console.log("|+|Connect Context",context);
            });
            centrifuge.on('error', function (e) {
                console.log('|+|Centrifuge Error: ',e);
            });

            //Check Permissions
            centrifuge.subscribe(`${CHANNELS.permission}_${customer_id}_${user_id}`, function (msg) {
                switch(msg.data.type) {
                    case 'update':
                        //get appPermissions
                        dispatch(permissionsActions.readPermissions());
                        //get userPermissions
                        dispatch(roleActions.readUserRole(msg.data.access_role_id));
                        //Refresh userProfile 
                        dispatch(userActions.viewUserProfile(msg.data.user_id));
                        break;
                    case 'delete':
                        //Check authentication and kick user out
                        dispatch(authActions.check()); 
                        break;
                    default:
                        console.log("|+|Message Error: ", msg)
                        break;
                } 
            });
            
            //Notifications
            centrifuge.subscribe(`${CHANNELS.notification}_${customer_id}_${user_id}`, function (msg) {
                const message = msg.data;
                dispatch(notificationsActions.hasUnReadNotifications());
                NotificationInfo.alert([message], () => onOpenNotification(message.external_id, message.notification_type, message.notification_id), true);
            });
            
            //TO DO: Make VOIP stack  
            centrifuge.subscribe(`${CHANNELS.voip}_${customer_id}`, function (msg) {
                console.log(msg);
                switch (msg.data.event) {
                    case 'channel_bridge':
                        dispatch(callActions.answerCall());
                        dispatch(officeViewActions.call({
                            ...msg,
                            uuid_call: msg.data.uuid_call,
                            name: msg.data.cid_name,
                            contactNumber: msg.data.cid_number,
                            callDetails                
                        }))
                        dispatch(notificationsActions.receiveNotification(msg.data, 'customer'));
                        break;
                    case 'channel_hangup':
                        dispatch(officeViewActions.closeActionsView());
                        dispatch(callActions.resolveCallResult());
                        dispatch(officeViewActions.hangup());
                        break;
                    case 'channel_create':
                        dispatch(callActions.receiveCall(msg.data.uuid_channel));
                        dispatch(officeViewActions.setActiveCall({
                            name: msg.data.dest_cid_name,
                            contactNumber: msg.data.dest_cid_number,
                            ...msg
                        }));
                        break;
                    default:
                        console.log("|+|Message Error: ", msg)
                        break;
                }
            });
           
            centrifuge.on('disconnect', function (context) {
                console.log("|+|Centrifuge Disconnect",context);
            })
            centrifuge.connect()
        }
        wsConnection();

        //unMount
        return () => {
            centrifuge.disconnect();
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        onToggleModal,
        taskModalOpen,
        setTaskModalOpen,
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        handleSubmitEvent,
        resetField,
        taskControl,
        taskWatch,
        taskIsValid,
        taskIsDirty,
        taskTrigger,
        taskSetValue,
        taskGetValues,
        taskClearErrors,
        taskSetError,
        taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDeleteTask,
        onUpdateTask,
        handleSubmit,
        task_labels,
        organizer_id,
        organizer,
        organizer_email,
        eventModalOpen,
        emailModalOpen,
        emailData,
        person_id,
        onDelete,
        onUpdate,
        setEventModalOpen,
        onOpenNotification,
        current_user,
        setNotificationContactName
    } = NotificationPopupModals();

    return (
        <>
            <SendEmailModal
                open={emailModalOpen}
                onClose={() => { if(emailModalOpen){onToggleModal("email", 0)} }}
                contact_id={person_id}
            />
            <AddTaskModal
                open={taskModalOpen}
                onClose={() => setTaskModalOpen(false)}
                state={state}
                control={taskControl}
                watch={taskWatch}
                isValid={taskIsValid}
                isDirty={taskIsDirty}
                trigger={taskTrigger}
                setValue={taskSetValue}
                getValues={taskGetValues}
                clearErrors={taskClearErrors}
                setError={taskSetError}
                reset={taskReset}
                taskBeingEdited={taskBeingEdited}
                loadTask={loadTask}
                addTask={addTask}
                setState={setState}
                onDelete={onDeleteTask}
                onUpdate={onUpdateTask}
                task_labels={task_labels}
                organizer_id={organizer_id}
                organizer={organizer}
                organizer_email={organizer_email}
                handleSubmit={handleSubmit}
                onRefresh={() => {
                    setTaskModalOpen(false);
                }}
                email={emailData} 
            />
            <AddEventModal
                open={eventModalOpen}
                onClose={() => setEventModalOpen(false)}
                onEdit={handleSubmitEvent(onUpdate)}
                onDeleteEvent={onDelete}
                eventBeingEdited={eventBeingEdited}
                selected_users={[current_user]}
                isAllowedToModify={isAllowedToModify}
                control={control}
                watch={watch}
                isValid={isValid}
                isDirty={isDirty}
                trigger={trigger}
                setValue={setValue}
                getValues={getValues}
                reset={reset}
                resetField={resetField}
                setNotificationContactName={setNotificationContactName}
            />
        </>
    )
}

export default WebsocketConnection;

