import React from 'react';
import { TimelineTabDisplay, GridList, DateTimeUtils } from 'dyl-components';
import { Segment, Popup } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const STATUS = {
    'complete': 'done',
    'missed': 'missed',
    'upcoming': 'upcoming',
}

const getStatus = (status) => {
    switch (status) {
        case 'missed':
            return 'Overdue';
        case 'complete':
            return 'Completed';
        case 'upcoming':
            return 'Upcoming';
        default:
            return '';
    }
}

const ICONS = {
    'To-Do': 'fas fa-clipboard-check',
    'Email': 'fas fa-envelope',
    'Call': 'fas fa-phone',
    'Text': 'fas fa-comment'
};


const Timeline = ({ isFormTabOpen, tasks, onEditTask, isReadingTasks, isContact }) => {

//Set the timeline to return times in the users timezone
const myTimeZone = useSelector(state => state.auth.timezone);
DateTimeUtils.setTimezone(myTimeZone);

    return (
        tasks.length <= 0 && !isReadingTasks ? (
            <Segment basic>
                <i>There are no tasks</i>
            </Segment>
        )
            :
            <TimelineTabDisplay
                titles={isContact ? [
                    { title: 'Name', width: 5 },
                    { title: 'Assigned', width: 3 },
                    { title: 'Status' },
                ] : [
                    { title: 'Name', width: 5 },
                    { title: 'Assigned', width: 2 },
                    { title: 'Contact', width: 2 },
                    { title: 'Status' },
                ]}
                expanded={isFormTabOpen}
                selectedRow={(email) => onEditTask(email.id)}
                itemComponent={tasks.map(item => {
                    const timestamp = !item.complete ? item.ts : item.activity;
                   
                    const date = `${DateTimeUtils.formatEpoch(timestamp, 'ddd M/D')}`.toUpperCase();
                    const time = DateTimeUtils.formatEpoch(timestamp);
                    const contactName = !isContact ? `${item.contact?.first_name} ${item.contact?.last_name}` : 'NN'; 
                    const assigned_name = `${item.assigned_to?.first_name} ${item.assigned_to?.last_name}`;
                    return ({
                        id: item.id,
                        date,
                        time,
                        icon: ICONS[item.task_type?.name],
                        itemComponent: <React.Fragment>
                            <GridList.Cell width={5} className='TimelineTabDisplay__Info'>
                                <Popup
                                    trigger={<span>{item.name}</span>}
                                    content={item.name}
                                    inverted />
                            </GridList.Cell>
                            <GridList.Cell width={isContact ? 3 : 2}>
                                <Avatar
                                    name={assigned_name}
                                    round
                                    size='2em'
                                    maxInitials={2} />
                            </GridList.Cell>
                            {!isContact && (
                                <GridList.Cell width={2}>
                                    <Link target="_blank" to={`/contact/${item?.contact.id}`}>
                                        <Avatar
                                            name={contactName}
                                            round
                                            size='2em'
                                            maxInitials={2}
                                        />
                                    </Link>
                                </GridList.Cell>
                            )}
                            <GridList.Cell>
                                <span className={`Info__status__TimelineDisplay Info__status__TimelineDisplay--${STATUS[item.status]}`}>
                                    {getStatus(item.status)}
                                </span>
                            </GridList.Cell>
                        </React.Fragment>,
                        markerColor: '#e5e5e5',
                        selected: item.status === 'complete'
                    });
                })} />
    );
};

export default Timeline;
