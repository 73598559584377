import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import pbxConfigActions from 'actions/pbx_config';
import DestinationGroupsTable from './subcomponents/DestinationGroupsTable';
import BulkActionsProvider from 'shared/context/BulkActionsProvider';
import { Pagination, Segment } from 'semantic-ui-react';
import './index.scss';
import Toolbar from './subcomponents/Toolbar';
import DestinationGroupModal from 'shared/modals/DestinationGroupModal';
import { Notification, STATUS_TYPES } from 'dyl-components';

const LIST_VIEW_URL = "/settings/phone-management/routing/destination-groups"
const LIMIT = 25;

const DestinationGroups = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const searchQuery = params.get('search') || '';
    const page = params.get("page") || "";
    const order = params.get("order") || ""
    const [search, setSearch] = useState(searchQuery);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const { destinationGroupsCount: count, isReadingDestinationGroups } = useSelector((state) => state.pbx_config);

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }
    
    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`,);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const onOpenModal = () => {
        setIsModalOpen(true)
    }

    const onCloseModal = () => {
        setIsModalOpen(false)
        setIsEditing(false);
    }

    const onReload = () => {
        dispatch(pbxConfigActions.getDestinationGroups(params));
    }

    const onSelect = async (destination_list) => {
        setIsEditing(true);
        await dispatch(pbxConfigActions.getDestinationGroup(destination_list));
        setIsModalOpen(true);
    }

    const onDelete = async (destination_list) => {
        try {
            await dispatch(pbxConfigActions.deleteDestinationGroup(destination_list));
            Notification.alert('Destination Group deleted successfully!', STATUS_TYPES.SUCCESS);
            onReload();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to delete Destination Group', STATUS_TYPES.ERROR);
        }
    }

    const [filters] = useState({
        type: params.get('type')?.split(','),
        destination_type: params.get('destination_type')?.split(',')
    })

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        if (order.trim()) {
            query.set('order', order);
        } else {
            query.delete('order');
        }
        const query_string = query.toString();
        navigate(`${LIST_VIEW_URL}${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() => {
        dispatch(pbxConfigActions.getDestinationGroups(params));
    }, [params, dispatch])

    return <>
        <div className='DestinationGroups'>
            <Toolbar
                onChangeSearch={onChangeSearch}
                cancelFunction={cancelFunction}
                onSearchSubmit={onSearchSubmit}
                onOpenModal={onOpenModal}
                search={searchQuery}
                isReadingDestinationGroups={isReadingDestinationGroups}
            />
            <DestinationGroupsTable isFiltering={!!search} onDelete={onDelete} onSelect={onSelect} />
            {count > 0 && (
                <Segment basic textAlign="right">
                    <Pagination
                        boundaryRange={0}
                        activePage={page}
                        ellipsisItem={null}
                        siblingRange={2}
                        totalPages={Math.ceil(count / LIMIT)}
                        onPageChange={onPageChange}
                        disabled={isReadingDestinationGroups}
                    />
                </Segment>
            )}
        </div>
        <DestinationGroupModal
            open={isModalOpen}
            onClose={onCloseModal}
            onReload={onReload}
            isEditing={isEditing}
        />
    </>
}

export default () => (
    <BulkActionsProvider>
        <DestinationGroups />
    </BulkActionsProvider>
);